//https://www.youtube.com/watch?v=K-bxVELldCc
import { useContext, useEffect, useState } from "react";
//import { createPalette } from "@mui/material/styles";
import { RouterProvider } from "react-router-dom";
import { UserContext } from "./services/contexts/UserContext";
import UserContextProvider from "./services/contexts/UserContextProvider";
import LoginUI from "./pages/Auth/LoginUI";
import GetRoutesBasedOnUser from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NetworkErrorHandler from "./utils/NetworkErrorHandler";
//import config from "./config";

const App = () => {
  const { isLoggedIn, user } = useContext(UserContext);
  const [subscription, setSubscription] = useState(null);
  useEffect(() => {
    const getSubscription = async () => {
      if ("serviceWorker" in navigator) {
        try {
          const serviceWorker = await navigator?.serviceWorker?.getRegistration(
            "/" // Replace with your service worker scope if different
          );
          const pushSubscription =
            await serviceWorker?.pushManager?.getSubscription();
          if (pushSubscription) setSubscription(pushSubscription);
        } catch (error) {
          console.error("Error getting push subscription:", error);
        }
      }
    };

    getSubscription();
  }, []);
  /*
  const subscribeToPush = async (e) => {
    // Check for service worker support
    console.log("subscribeToPush");
    if ("serviceWorker" in navigator && "PushManager" in window) {
      try {
        const registration = await navigator.serviceWorker.register(
          "/service-worker.js"
        );
        console.log("Service Worker registered:", registration);
        console.log(process?.env?.REACT_APP_PUBLIC_VAPID_KEY);
        const subscription = await registration?.pushManager?.subscribe({
          userVisibleOnly: true,
          applicationServerKey: config?.publicVapidKey,
        });
        setSubscription(subscription);
        // Send subscription to backend API
        const result = await sendSubscriptionToBackend(subscription);
        // Send subscription to the server
        console.log("result", result);
        console.log("User is subscribed for push notifications:", subscription);
      } catch (error) {
        console.error("Failed to subscribe for push notifications:", error);
      }
    } else {
      console.warn("Push messaging is not supported");
    }
  };
  const sendSubscriptionToBackend = async (pushSubscription) => {
    try {
      const response = await fetch(`${config.apiUrl}/notification/subscribe`, {
        method: "POST",
        body: JSON?.stringify(pushSubscription),
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) {
        console.error(
          "Error sending subscription to backend:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error sending subscription to backend:", error);
    }
  };*/

  const routes = GetRoutesBasedOnUser();
  const renderContent = () => {
    if (isLoggedIn && user) {
      return (
        <UserContextProvider>
          <RouterProvider router={routes} fallbackElement={<LoginUI />} />
        </UserContextProvider>
      );
    } else if (!window.location.href.includes("/forgotPassword")) {
      return <LoginUI />;
    }
    return null;
  };

  return (
    <>
      <NetworkErrorHandler>
        {renderContent()}
        <ToastContainer />
        {!subscription && (
          <></>/*<button
            style={{ float: "right" }}
            id="subscribe"
            onClick={(e) => subscribeToPush(e)}
          >
            Subscribe to Push
          </button>*/
        )}
      </NetworkErrorHandler>
    </>
  );
};

export default App;
