import { useContext, useEffect } from "react";
import { Draw } from "ol/interaction";
import MapContext from "../Map/MapContext";
//target can be features, a source or a layer
const DrawInteraction =({source,type}) => {
	const { map } = useContext(MapContext);

	useEffect(() => {
		if (!map) return;
		console.log('drawing added to interaction')
		let drawInteraction = new Draw({source: source,type: type});
		
		drawInteraction.on('drawend', (event) => {
			// Access the newly drawn feature
			const feature = event.feature;
	  
			// Get the feature ID (if available)
			const featureId = feature?.getId();
	  
			// Now you can safely use the featureId
			console.log("New feature ID:", featureId);
	  
			// ... your other logic to handle the new feature
		  });

		map.interactions.push(drawInteraction);

		return () => map.interactions.remove(drawInteraction);
	}, [map]);//,source,type

	return null;
};

export default DrawInteraction;