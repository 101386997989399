import { Container, Grid } from "@mui/material";

import { IPDCWelcomeMessage } from "../../../components"; //IPDCMessageCard
import {
  ParksBySectorCard,
  ParksByOccupancyCard,
  HandoverCard
} from "../Shared/DashboardCards";
import ViewMessageList from "../Shared/Message/ViewMessageList";

export default function ParkStaffDashboard() {
    return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <IPDCWelcomeMessage messageTitle="Park Staff" />
        </Grid>
        <Grid item xs={12} md={6}>
          <ParksByOccupancyCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <ParksBySectorCard />
        </Grid>
        <Grid item xs={12} md={12}>
          <HandoverCard/>
        </Grid>

        <Grid item xs={12}>
          <ViewMessageList isSearchable={true} />
        </Grid>
      </Grid>
    </Container>
  );
}
