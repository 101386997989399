//date, currency, string
function CapitalizeWords(sentence) {
    return sentence
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
  const StripUnderscoreAndCapitalizeLeters=(label)=>{
    return label
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  }
  export {CapitalizeWords, StripUnderscoreAndCapitalizeLeters}