import React, { useEffect, useState } from "react";
import IPDCFormHeader from "../../../../components/Forms/IPDCFormHeader";
import { Box, Stepper, Step, StepLabel, Paper } from "@mui/material";
import { Step1, Step2, Step3, Step4, Step5, Step6 } from "./RegisterAgreementTabs";
import { ExecutePostWithParams, GetDataFromApiWithParams } from "../../../../services/api/ExecuteApiRequests";
//prettier-ignore
const initialData = { tenant_id: "", upin: "", ownership_type: "", investment_type: "", contract_issue: "", contract_renewal: "", 
  moe_signed: "", production_capacity: "", handover_date: "", rehandover_date: "", production_commencement_date: "", 
  market_destination: [], intl_brands_link: [], disposal_method: "", export_capacity: "", employee_capacity: "", monthly_rent: "", 
  grace_period_ending_date: "", security: "", advance_payment: "", annual_mngmnt_service_fee: "", payment_mode: "", 
  late_charge_fee: "", contract_period: "", description: "", agreement_id: "", current_status: "NEW", mou_attachment: "" 
};
//prettier-ignore
const steps = [ 
  "Basic Information", "Investment & Contract Details", "Production & Capacity", "Market & Disposal", "Financial & Contractual Details", "Summary & Description"
];

export default function RegisterAgreement() {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedPark, setSelectedPark] = useState(null);
  const [parcelInPark, setParcelInPark] = useState("");
  const [selectedInvestor, setSelectedInvestor] = useState(null);
  const [messageFromApi, setMessageFromApi] = useState("");
  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    const fetchUPIN = async parkId => {
      const responseData = await GetDataFromApiWithParams(`availableParcelsInPark/${parkId}`, {
        params: { id: parkId },
      });
      if (responseData) {
        setParcelInPark(responseData);
      }
    };
    if (selectedPark) {
      fetchUPIN(selectedPark.id);
      console.log(selectedPark.id);
    }
  }, [selectedPark]);

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === "mou_attachment") {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: e.target.files[0],
      }));
      return;
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmission = async e => {
    e.preventDefault();
    try {
      //todo consider adding attachment mou
      formData.tenant_id = selectedInvestor ? selectedInvestor.id : null;
      const responseData = await ExecutePostWithParams("agreements", formData, false);

      if (responseData) {
        setMessageFromApi('<div class="success">Agreement successfully saved</div>');
        setFormData(initialData);
        setSelectedPark(null);
        setSelectedInvestor(null);
        setParcelInPark(null);
        setCurrentStep(0);
      } else {
        setMessageFromApi('<div class="error">Error on saving agreement please try again</div>');
      }
      //console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const nextStep = e => {
    e.preventDefault();
    setCurrentStep(prevStep => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };

  return (
    <Paper elevation={3}>
      <Box p={3}>
        <IPDCFormHeader title={"Register Agreement"} />
        <Stepper activeStep={currentStep}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {currentStep === 0 && (
          <Step1
            formData={formData}
            handleChange={handleChange}
            selectedPark={selectedPark}
            setSelectedPark={setSelectedPark}
            parcelInPark={parcelInPark}
            selectedInvestor={selectedInvestor}
            setSelectedInvestor={setSelectedInvestor}
            nextStep={nextStep}
          />
        )}
        {currentStep === 1 && (
          <Step2 formData={formData} handleChange={handleChange} nextStep={nextStep} prevStep={prevStep} />
        )}
        {currentStep === 2 && (
          <Step3 formData={formData} handleChange={handleChange} nextStep={nextStep} prevStep={prevStep} />
        )}
        {currentStep === 3 && (
          <Step4 formData={formData} handleChange={handleChange} nextStep={nextStep} prevStep={prevStep} />
        )}
        {currentStep === 4 && (
          <Step5 formData={formData} handleChange={handleChange} nextStep={nextStep} prevStep={prevStep} />
        )}
        {currentStep === 5 && (
          <Step6
            formData={formData}
            handleChange={handleChange}
            handleSubmission={handleSubmission}
            prevStep={prevStep}
          />
        )}
        {messageFromApi && <div dangerouslySetInnerHTML={{ __html: messageFromApi }} />}
      </Box>
    </Paper>
  );
}
