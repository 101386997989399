import { useState, useEffect, useContext } from "react";
import {
  Box,
  Container,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { ViewMap } from "../MapRelated/Views";
import MapContext from "../MapRelated/Map/MapContext";
import useMap from "../MapRelated/Map/useMap";
import { jsPDF } from "jspdf";
import ScaleLine from "ol/control/ScaleLine";
import "./Print-Styling.css";

// Constants
const PAPER_SIZES = ["A4", "A3", "A2", "A1", "A0"];
const DIMENSIONS = {
  A0: [1189, 841],
  A1: [841, 594],
  A2: [594, 420],
  A3: [420, 297],
  A4: [297, 210],
};
const RESOLUTIONS = [
  { value: "72", label: "72 dpi (fast)" },
  { value: "150", label: "150 dpi (medium)" },
  { value: "200", label: "200 dpi (medium)" },
  { value: "300", label: "300 dpi (slow)" },
];
const SCALES = [
  { value: "50000", label: "1:50000" },
  { value: "25000", label: "1:25000" },
  { value: "10000", label: "1:10000" },
  { value: "5000", label: "1:5000" },
  { value: "2500", label: "1:2500" },
  { value: "1000", label: "1:1000" },
  { value: "500", label: "1:500" },
  { value: "250", label: "1:250" },
  { value: "100", label: "1:100" },
];

const PrintMapForm = () => {
  const { visibleLayers, refreshMap } = useMap();
  const { map } = useContext(MapContext);

  const [title, setTitle] = useState("");
  const [mapUnit, setMapUnit] = useState("m");
  const [dpi, setDpi] = useState(72);
  const [scale, setScale] = useState(25);
  const [paperSize, setPaperSize] = useState("A4");
  const [paperOrientation, setPaperOrientation] = useState("portrait");
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    if (map) {
      const scaleLineControl = new ScaleLine({ units: mapUnit });
      map.addControl(scaleLineControl);
    }
  }, [map, mapUnit]);
  /*const calculateScaleFactor = () => {
    if (!map || !map.getView()) {
      console.error("Map view is not available. Cannot calculate scale factor.");
      return 1; // Default to no scaling
    }
  
    const zoomLevel = map.getView().getZoomForResolution( 1 / dpi);
    console.log("zoom level", zoomLevel)
    // Approximate scale factors based on zoom level and map projection
   const scaleFactors = {
      10: 100000,
      12: 50000,
      14: 25000,
      // ... other zoom levels and their corresponding scale factors
    };
  
    const scaleFactor = scaleFactors[zoomLevel] || 1; // Default to no scaling if zoom level not found
  
    return scale / scaleFactor;
  };*/
  const calculateDimensions = () => {
    const [width, height] = DIMENSIONS[paperSize];
    const scaledWidth = paperOrientation === "landscape" ? height : width;
    const scaledHeight = paperOrientation === "landscape" ? width : height;
    const dpiScaleFactor = dpi / 25.4;
    //applying scale
    const scaledWidthWithScale = scaledWidth ;//* calculateScaleFactor();
    const scaledHeightWithScale = scaledHeight;// * calculateScaleFactor();
    console.log("scaledWidthWithScale",scaledWidthWithScale);
    return [scaledWidthWithScale/100 * dpiScaleFactor, scaledHeightWithScale * dpiScaleFactor];
    //return [scaledWidth * dpiScaleFactor, scaledHeight * dpiScaleFactor];
  };

  const createCanvas = async () => {
    const [canvasWidth, canvasHeight] = calculateDimensions();
    const mapCanvas = document.createElement("canvas");

    mapCanvas.width = paperOrientation === "landscape" ? canvasHeight : canvasWidth;
    mapCanvas.height = paperOrientation === "landscape" ? canvasWidth : canvasHeight;

    const context = mapCanvas.getContext("2d");
    context.fillStyle = "white";
    context.fillRect(0, 0, mapCanvas.width, mapCanvas.height);

    if (paperOrientation === "landscape") {
      context.translate(mapCanvas.width / 2, mapCanvas.height / 2);
      context.rotate((90 * Math.PI) / 180);
      context.translate(-mapCanvas.height / 2, -mapCanvas.width / 2);
    }

    document.querySelectorAll(".ol-layer canvas").forEach((canvas) => {
      if (canvas.width > 0) {
        context.globalAlpha = parseFloat(canvas.parentNode.style.opacity || 1);
        context.drawImage(canvas, 0, 0);
      }
    });

    context.setTransform(1, 0, 0, 1, 0, 0);

    if (title) {
      context.font = "bold 24px Arial";
      context.fillStyle = "black";
      context.textAlign = "center";
      context.fillText(title, mapCanvas.width / 2, 30);
    }

    return mapCanvas;
  };
  const MARGIN = 10; // 10mm margin

  const handlePrint = async () => {
    try {
      setResponseMessage("Rendering map for print...");
      const mapCanvas = await createCanvas();
      const pdf = new jsPDF(paperOrientation, "mm", DIMENSIONS[paperSize]);

      pdf.addImage(mapCanvas.toDataURL("image/jpeg"), "JPEG", MARGIN, MARGIN, DIMENSIONS[paperSize][0] - 2 * MARGIN,DIMENSIONS[paperSize][1] - 2 * MARGIN);
      pdf.save(`${title || "map"}.pdf`);

      setResponseMessage("Map exported successfully.");
    } catch (error) {
      setResponseMessage(`Error during export: ${error.message}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handlePrint();
  };

  return (
    <Container sx={{ position: "relative", height: "100vh", padding: 0, margin: 0 }}>
      <form id="printForm" onSubmit={handleSubmit}>
        <Box
          sx={{
            position: "fixed",
            top: "20%",
            right: "2%",
            zIndex: 20,
            width: "30%",
            p: 2,
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                Print Map Page
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Map Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="paper-size-label">Paper Size</InputLabel>
                <Select value={paperSize} onChange={(e) => setPaperSize(e.target.value)}>
                  {PAPER_SIZES.map((size) => (
                    <MenuItem key={size} value={size}>
                      {size}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="resolution-label">Resolution</InputLabel>
                <Select value={dpi} onChange={(e) => setDpi(e.target.value)}>
                  {RESOLUTIONS.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <ToggleButtonGroup
                value={paperOrientation}
                exclusive
                onChange={(e) => setPaperOrientation(e.target.value)}
                fullWidth
              >
                <ToggleButton value="portrait">Portrait</ToggleButton>
                <ToggleButton value="landscape">Landscape</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="scale-label">Scale</InputLabel>
                <Select value={scale} onChange={(e) => setScale(Number(e.target.value))}>
                  {SCALES.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Print Map
              </Button>
            </Grid>
            {responseMessage && (
              <Grid item xs={12}>
                <Typography color={responseMessage.includes("error") ? "error" : "success"}>
                  {responseMessage}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </form>

      <Box>
        <ViewMap showLegend={true} showSateliteImage={true} showPopup={false} />
      </Box>
    </Container>
  );
};

export default PrintMapForm;
