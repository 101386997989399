import React, { useState, useRef } from "react";
import ViewMap from "./Views/ViewMap.jsx";
import CustomButton from "../../../../../components/shared/Button/CustomButton.js";
//import OLFormatGeoJSON from "ol/format/GeoJSON";
import useMapData from "./hooks/useMapData.jsx";
//import { saveAs } from "file-saver"; // For client-side file saving
import shpwrite from "shp-write-pure"; // For Shapefile export
import tokml from "geojson-to-kml";
//import * as geojson2shp from "geojson2shp";

const featureTypes = [
  { key: "park", label: "Park" },
  { key: "BLOCKs", label: "Block" },
  { key: "PARCELs", label: "Parcel" },
  { key: "buildings", label: "Building" },
  { key: "roads", label: "Road" },
  { key: "greenareas", label: "Green Area" },
  { key: "powers", label: "Power supply" },
  { key: "communications", label: "Communication" },
  { key: "storages", label: "Storage" },
  { key: "wastedisposals", label: "Waste Disposal" },
  { key: "waters", label: "Water supply" },
  { key: "gcps", label: "GCPs" },
  { key: "surveyDatas", label: "Survey Point" },
  { key: "plans", label: "Plan" },
];

export default function ExportDataPage() {
  //const geojsonFormat = new OLFormatGeoJSON(); // Use ol/format/GeoJSON
  const mapRef = useRef(null); // Use useRef for map instance
  const [selectedFeatureType, setFeatureType] = useState(featureTypes[0].key);
  const [selectedFormat, setSelectedFormat] = useState("GeoJSON");
  const [responseMessage, setResponseMessage] = useState("");
  const data = useMapData();
  const exportAsGeojson = async resultToExport => {
    // Convert the result to a GeoJSON string
    const geojson = JSON.stringify(resultToExport);

    // Option 1: Download the file
    const blob = new Blob([geojson], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = selectedFeatureType + ".geojson";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    return selectedFeatureType + ".geojson";
  };
  const exportAsShape = async resultToExport => {
    try {
      const options = {
        outputType: "blob", // Specify output type here
      };

      const { compression = "STORE", ...restOptions } = options; // Set default 'STORE' for compression

      const shapefileData = await shpwrite.zip(resultToExport, {
        compression,
        ...restOptions,
      });
      if (shapefileData) {
        console.log(shapefileData);
        // Option 1: Download the file
        const blob = new Blob([shapefileData], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = selectedFeatureType + ".zip";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        return selectedFeatureType + ".zip";
      } else {
        return null;
      }
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  const exportAsKml = async resultToExport => {
    try {
      const kmlNameDescription = tokml(resultToExport);
      // Option 1: Download the file
      const blob = new Blob([kmlNameDescription], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = selectedFeatureType + ".kml";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      return selectedFeatureType + ".kml";
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      if (selectedFeatureType && selectedFormat) {
        console.log(selectedFeatureType?.toLowerCase(), selectedFormat)
        const resultToExport = data[selectedFeatureType?.toLowerCase()];
        console.log(data);
        if (!resultToExport) {
          setResponseMessage("Can't export right now, please wait and try again!");
          return;
        }
        if (selectedFormat === "GeoJSON") {
          const exportedAs = await exportAsGeojson(resultToExport);
          setResponseMessage('Successfully exported as "' + exportedAs + '" in your default download folder.');
          return;
        } else if (selectedFormat === "Shapefile") {
          console.log("Shape file xport started");
          const exportedAs = await exportAsShape(resultToExport);
          if (exportedAs)
            setResponseMessage('Successfully exported as "' + exportedAs + '" in your default download folder.');
          else setResponseMessage("Unable to export to shape because the features are in use, please try again later.");
        } else if (selectedFormat === "kml") {
          const exportedAs = await exportAsKml(resultToExport);
          setResponseMessage('Successfully exported as "' + exportedAs + '" in your default download folder.');
          return;
        }
      }
    } catch (error) {
      setResponseMessage("An error occurred: " + error.message);
      console.error(error);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="floating-form shadow-lg d-flex flex-column">
        <form>
          <div>
            <div className="form-title">Export Spatial Data</div>
            <select id="featureType" value={selectedFeatureType} onChange={e => setFeatureType(e.target.value)}>
              {featureTypes.map(type => (
                <option key={type.key} value={type.key}>
                  {type.label}
                </option>
              ))}
            </select>
            <div>
              <select id="format" value={selectedFormat} onChange={e => setSelectedFormat(e.target.value)}>
                <option value="GeoJSON">GeoJSON</option>
                <option value="Shapefile">Shapefile</option>
                <option value="kml">KML</option>
              </select>
            </div>
            <CustomButton title={"Export Data"} onClick={handleSubmit} />
          </div>
        </form>
        {responseMessage && <p>{responseMessage}</p>}
      </div>
      <ViewMap ref={mapRef} isEditable={false} showLegend={false} />
    </div>
  );
}
