import React, { useState, useEffect } from "react";
import { Box, Paper, Typography, Stack, Chip } from "@mui/material";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
//import IPDCFormHeader from "../../../../components/Forms/IPDCFormHeader";

function SystemHealth() {
  const [systemStatus, setSystemStatus] = useState("healthy");
  const [statusColor, setStatusColor] = useState("success");

  useEffect(() => {
    const checkNetwork = async () => {
      try {
        setSystemStatus("healthy");
        setStatusColor("success");
      } catch (error) {
        setSystemStatus("Network interupted");
        setStatusColor("error");
      }
    };

    const intervalId = setInterval(checkNetwork, 5000); // Check network every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Paper elevation={3} sx={{ width: '50%', verticalAlign: 'middle' }}>
      <Box p={10} m={10}>
        <Typography variant="h4" align="center">
          System Status:
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <HealthAndSafetyIcon fontSize="large" color={statusColor} />
          <Chip label={systemStatus} color={statusColor} variant="outlined" />
        </Stack>
      </Box>
    </Paper>
  );
}

export default SystemHealth;