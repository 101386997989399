import React from "react";
import { Grid, Typography, TextField, Button } from "@mui/material";

const Step6 = ({ formData, handleChange, handleSubmission, prevStep }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="h6">Step 6: Summary & Description</Typography>
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth={true}
        type="number"
        label="Contract Period in Months"
        id="contract_period"
        name="contract_period"
        value={formData.contract_period}
        onChange={handleChange}
        required
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth={true}
        label="Description"
        id="description"
        name="description"
        value={formData.description}
        onChange={handleChange}
        rows={3}
        multiline= {true}
      />
    </Grid>

    <Grid item md={4}>
      <Typography variant="h6">MOU Attachment</Typography>
    </Grid>
    <Grid item md={4}>
      <Button variant="contained" component="label">
        Attach
        {/*<input
          type="file"
          hidden
          accept="image/*, application/pdf"
          name="mou_attachment"
          value={formData.mou_attachment}
          //onChange={handleChange}
          onChange={(e) =>  formData.mou_attachment=e.target.files[0] }
        />*/}
      </Button>
    </Grid>
    <Grid item xs={4}>
     {
      //<Typography variant="body1">{formData?.mou_attachment ? formData?.mou_attachment : "No file selected"}</Typography>
     }
      
    </Grid>
    <Grid item xs={12}>
      <Button variant="contained" onClick={prevStep}>
        Previous
      </Button>
      <Button variant="contained" onClick={handleSubmission} style={{ marginLeft: "8px" }}>
        Submit
      </Button>
    </Grid>
  </Grid>
);

export default Step6;
