import { useEffect, useState } from "react";
import {
  IPDCPieChart,
  SquareChartContainer,
} from "../../../../../components/Charts";
import { Factory } from "@mui/icons-material";
import { Link } from "react-router-dom";

import {ExecuteApiRequest, GetDataFromApiWithParams} from "../../../../../services/api/ExecuteApiRequests";

export default function LandUseProportionCard({ parkId = null }) {
  const [parkOccupancyData, setParkOccupancyData] = useState([]);

  useEffect(() => {
    const fetchParkOccupancyData = async () => {
      try {
        //shedVSParcelDevelopment
        const parkOccupancy = await GetDataFromApiWithParams(`dashboard/mp/landUseProportion`);
       // console.log(parkOccupancy);
        setParkOccupancyData(parkOccupancy || []);
      } catch (e) {
        console.error(e);
      }
    };
    fetchParkOccupancyData();
  }, [parkId]);
    return (
    <>
      {parkOccupancyData && (
        <SquareChartContainer
          THECHART={
            <IPDCPieChart
              data={parkOccupancyData}
              showButton={false}
              nameKey="category"
              dataKey="count"
              withHole={true}
            />
          }
          title="Land Use Proportion"
          link="/landUseProportion"
          showMoreButton={false}
          avatar={<Factory />}
        />
      )}
    </>
  );
}
