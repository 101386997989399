import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { IconButton, Badge, Container } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { GetUnreadMessagesCount } from "../../pages/Views/Shared/CommonData/CommonData";
import useDecodedUser from "../../services/hooks/useDecodedUser";

export default function IPDCNotification() {
  const decodedUser = useDecodedUser();
  const [noOfMessages, setNoOfMessages] = useState(1);

  useEffect(() => {
    const fetchMessage = async () => {
      const count = await GetUnreadMessagesCount({ userId: decodedUser?.id });
      setNoOfMessages(count);
    };
    fetchMessage();
  }, [decodedUser]);
  return (
    <Container>
      <NavLink to="/viewMessageList" style={{ textDecoration: "none", background: "none", border: "none" }}>
        <IconButton size="large" aria-label="show new notifications" color="inherit">
          <Badge badgeContent={noOfMessages} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </NavLink>
    </Container>
  );
}
