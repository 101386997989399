import MapDataProvider from "../../../pages/Views/LandInfraDevelopment/GISView/MapRelated/MapData/MapDataProvider";
import { Route } from "react-router-dom";
import {
  ViewMap,
  ViewMapByCategoryPage,
  ViewInfrastructureMap,
  ViewCadastreMap,
} from "../../../pages/Views/LandInfraDevelopment/GISView/MapRelated/Views";
import ExportDataPage from "../../../pages/Views/LandInfraDevelopment/GISView/MapRelated/ExportDataPage";
import PrintMapForm from "../../../pages/Views/LandInfraDevelopment/GISView/Forms/PrintMapForm";

import BuildingPermitList from "../../../pages/Views/LandInfraDevelopment/Head/BuildingPermitList";
import OccupancyPermitList from "../../../pages/Views/LandInfraDevelopment/Head/OccupancyPermitList";

import GISReports from "../../../pages/Views/LandInfraDevelopment/GISView/Reports/GISReports";
import LandInfraDevelopmentDashboard from "../../../pages/Views/LandInfraDevelopment/Head/LandInfraDevelopmentDashboard";
export default function LandAndInfraDevelopmentHeadRoutes() {
  return (
    <>
      <Route path="/" element={<LandInfraDevelopmentDashboard />} />
      <Route path="/viewMap" element={<ViewMap />} />
      <Route path="/viewMapByCategory" element={<ViewMapByCategoryPage />} />
      <Route path="/infrastructureMap" element={<ViewInfrastructureMap />} />
      <Route path="/viewCadastreMap" element={<ViewCadastreMap />} />
      <Route path="/printMap" element={<PrintMapForm />} />
      <Route path="/exportMap" element={<MapDataProvider><ExportDataPage /></MapDataProvider>} />

      <Route path="/gisReports" element={<GISReports />} />
      <Route path="/listBPermit" element={<BuildingPermitList />} />
      <Route path="/listOPermit" element={<OccupancyPermitList />} />
    </>
  );
}
