const IPDCSearchTermsAndLinks = {
  SA: [
    { searchKey: ["home"], link: "/" },
    { searchKey: ["dashboard"], link: "/" },
    { searchKey: ["user", "list"], link: "/userManagement" },
    {
      searchKey: ["activate/deactivete ", "user"],
      link: "/activateDeactivateUser",
    },
    { searchKey: ["department", "list"], link: "/registerDepartment" },

    { searchKey: ["log"], link: "/systemLog" },
    { searchKey: ["health"], link: "/systemHealth" },
    { searchKey: ["config"], link: "/systemConfig" },
    { searchKey: ["prefe"], link: "/systemConfig" },
    { searchKey: ["report"], link: "/systemAdminReport" },
    { searchKey: ["add new user"], link: "/registerUser" },
  ],
  EXEC: [
    { searchKey: ["home"], link: "/" },
    { searchKey: ["dashboard"], link: "/" },
    { searchKey: ["reserve shed/parcel"], link: "/placeOnHold" },
    { searchKey: ["hold shed/parcel"], link: "/placeOnHold" },
    { searchKey: ["investor ", "Activities"], link: "/execActivities" },
    { searchKey: ["finance"], link: "/execFinance" },
    { searchKey: ["view map"], link: "/viewMapByCategory" },
    { searchKey: ["report"], link: "/execReport" },
    { searchKey: ["preference"], link: "/myPreference" },
  ],
  EIC: [
    { searchKey: ["home"], link: "/" },
    { searchKey: ["prospective investor"], link: "/prospectiveInvestor" },
    { searchKey: ["investor activities"], link: "/referToIPDC" },
    { searchKey: ["finance"], link: "/viewReferredStatus" },
    { searchKey: ["print deed"], link: "/Re/PrintDeed" },
    { searchKey: ["report"], link: "/eicReports" },
    { searchKey: ["preference"], link: "/myPreference" },
  ],
  PROM: [
    { searchKey: ["home"], link: "/" },
    { searchKey: ["dashboard"], link: "/" },
    { searchKey: ["prospective investor"], link: "/prospectiveInvestor" },
    { searchKey: ["investor activities"], link: "/referToIPDC" },
    { searchKey: ["finance"], link: "/viewReferredStatus" },
    { searchKey: ["print deed"], link: "/Re/PrintDeed" },
    { searchKey: ["report"], link: "/promReport" },
    { searchKey: ["preference"], link: "/myPreference" },
  ],
  GIS: [
    { searchKey: ["home"], link: "/" },
    { searchKey: ["dashboard"], link: "/" },
    { searchKey: ["parcel list"], link: "/parcelList" },
    { searchKey: ["view map"], link: "/viewMap" },
    { searchKey: ["view map by category"], link: "/viewMapByCategory" },
    { searchKey: ["edit map"], link: "/editMap" },
    { searchKey: ["assign info to features"], link: "/assignInfo" },
    { searchKey: ["import spatial data"], link: "/importSpatial" },
    { searchKey: ["export spatial data"], link: "/exportMap" },
    { searchKey: ["print map"], link: "/printMap" },
    { searchKey: ["reports"], link: "/gisReports" },
    { searchKey: ["preference"], link: "/myPreference" },
  ],
  LI: [
    { searchKey: ["home"], link: "/" },
    { searchKey: ["dashboard"], link: "/" },
    { searchKey: ["view map"], link: "/viewMap" },
    { searchKey: ["view map by category"], link: "/viewMapByCategory" },
    { searchKey: ["view map by category"], link: "/viewMapByCategory" },
    { searchKey: ["consultants"], link: "/manageConsultants" },
    { searchKey: ["contractors"], link: "/manageContractors" },
    { searchKey: ["list  building permit"], link: "/listBPermit" },
    { searchKey: ["list occupancy permit"], link: "/listOPermit" },
    { searchKey: ["reports"], link: "/landInfraReports" },
    { searchKey: ["Preference"], link: "/myPreference" },
  ],
  DES: [
    { searchKey: ["home"], link: "/" },
    { searchKey: ["dashboard"], link: "/" },
    { searchKey: ["view map by category"], link: "/viewMapByCategory" },
    { searchKey: ["issue building permit"], link: "/issueBuildingPermit" },
    { searchKey: ["issue occupancy permit"], link: "/issueOccupancyPermit" },
    { searchKey: ["register occupancy permit"], link: "/registerOPrequest" },
    { searchKey: ["consultants"], link: "/manageConsultants" },
    { searchKey: ["contractors"], link: "/manageContractors" },
    { searchKey: ["register building permit"], link: "/registerBPrequest" },
    { searchKey: ["list  building permit"], link: "/listBPermit" },
    { searchKey: ["register comment"], link: "/registerComment" },
    { searchKey: ["list occupancy permit"], link: "/listOPermit" },
    { searchKey: ["reports"], link: "/designReports" },
    { searchKey: ["Preference"], link: "/myPreference" },
  ],
  FINANCE: [
    { searchKey: ["home"], link: "/" },
    { searchKey: ["dashboard"], link: "/" },
    { searchKey: ["collect payment"], link: "/collectPayment" },
    { searchKey: ["rule"], link: "/financeRule" },
    { searchKey: ["import existing collection"], link: "/importExistingCollection" },
    { searchKey: ["report", "arrear", "uncollected", "report"], link: "/financeSummary" },
    { searchKey: ["Preference"], link: "/myPreference" },
  ],
  "OP-IA": [
    { searchKey: ["home"], link: "/" },
    { searchKey: ["dashboard"], link: "/" },
    { searchKey: ["register investor"], link: "/registerInvestor" },
    { searchKey: ["register agreement"], link: "/registerAgreement" },
    { searchKey: ["register termination request"], link: "/registerTerminationRequest" },
    { searchKey: ["register investor activities"], link: "/investorActivities" },
    { searchKey: ["investor", "list"], link: "/investorList" },
    { searchKey: ["parcel", "list"], link: "/parcelList" },
    { searchKey: ["property transfer"], link: "/transferProperty" },
    { searchKey: ["report"], link: "/InvAfterCareReport" },
    { searchKey: ["import existing tenants"], link: "/importTenant" },
    { searchKey: ["Preference"], link: "/myPreference" },
  ],
  "000": [
    { searchKey: ["home"], link: "/" },
    { searchKey: ["dashboard"], link: "/" },
    { searchKey: ["view map by category"], link: "/viewMapByCategory" },
    { searchKey: ["view map"], link: "/viewMap" },
    { searchKey: ["register handover"], link: "/registerHandover" },
    { searchKey: ["print map"], link: "/printMap" },
    { searchKey: ["parcel list"], link: "/listParcel" },
    { searchKey: ["investor list"], link: "/investorList" },
    { searchKey: ["report"], link: "/PASummaryReport" },
    { searchKey: ["Preference"], link: "/myPreference" },
  ],
};
export default IPDCSearchTermsAndLinks;
