import React from "react";
import { Grid, Typography, TextField, Button } from "@mui/material";

const Step4 = ({ formData, handleChange, nextStep, prevStep }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="h6">Step 4: Market & Disposal</Typography>
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth={true}
        label="Market Destination"
        id="market_destination"
        name="market_destination"
        value={formData.market_destination}
        onChange={handleChange}
        required
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth={true}
        label="Link to International Brands"
        id="intl_brands_link"
        name="intl_brands_link"
        value={formData.intl_brands_link}
        onChange={handleChange}
        required
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth={true}
        label="Waste Disposal Method"
        id="disposal_method"
        name="disposal_method"
        value={formData.disposal_method}
        onChange={handleChange}
        required
      />
    </Grid>
    <Grid item xs={12}>
      <Button variant="contained" onClick={prevStep}>
        Previous
      </Button>
      <Button variant="contained" onClick={nextStep} style={{ marginLeft: "8px" }}>
        Next
      </Button>
    </Grid>
  </Grid>
);

export default Step4;
