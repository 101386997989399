import React, { useState } from 'react';
import '../../../../../styles/sample-form.css';
import { ExecuteApiToPost } from '../../../../../services/api/ExecuteApiRequests';
const SplitForm = React.forwardRef((onSplit, ref) => {
	const [upin, setUpin] = useState(null);
	const [X1, setX1] = useState('');
	const [X2, setX2] = useState('');
	const [Y1, setY1] = useState('');
	const [Y2, setY2] = useState('');
	const [description, setDescription] = useState('');
	const [responseMessage, setResponseMessage] = useState('');

	const handleSubmit = async (e) => {
		e.preventDefault();
		let apiToCall = 'splitParcel';
		const tempUpin = document.getElementById('upin').value;
		console.log(tempUpin);
		let parameterValues = {
			upin: tempUpin,
			x1: X1,
			y1: Y1,
			x2: X2,
			Y2: Y2,
			description: description,
		};


		const responseData = await ExecuteApiToPost(apiToCall, parameterValues);
		if (responseData === 201) {
			setResponseMessage('Registration successful!');
		} else {
			setResponseMessage('Registration failed: ' + responseData);
		}
	};

	return (
		<div className="floating-form shadow-lg d-flex flex-column">
			<form onSubmit={handleSubmit} ref={ref}>
				<div className="form-title">Parcel Subdivide</div>
				<div className="input-group row mb-1">
					<span className="input-group-text " id="basic-addon1">
						UPIN
					</span>
					<input
						className="form-control inputGroup-sizing-sm"
						type="text"
						id="upin"
						value={upin}
						onChange={(e) => setUpin(e.target.value)}
						placeholder="Enter UPIN"
						required
					/>
				</div>

				<div className="form-group row mb-1">
					<label for="upin" className="mb-1">
						Coordinate 1
					</label>
					<div className="input-group input-group-sm mb-1">
						<span
							className="input-group-text pb-0"
							id="basic-addon1"
						>
							X1
						</span>
						<input
							className="form-control  "
							aria-describedby="inputGroup-sizing-sm"
							aria-label="Sizing example input"
							type="text"
							id="X1"
							value={X1}
							onChange={(e) => setX1(e.target.value)}
							placeholder="Enter X1"
							required
						/>
					</div>
					<div className="input-group input-group-sm mb-1">
						<span
							className="input-group-text pb-0"
							id="basic-addon1"
						>
							Y1
						</span>
						<input
							className="form-control"
							type="text"
							id="Y1"
							value={Y1}
							onChange={(e) => setY1(e.target.value)}
							placeholder="Enter Y1"
							required
						/>
					</div>
				</div>
				<div className="form-group row">
					<label for="upin">Coordinate 2</label>
					<div className="input-group input-group-sm mb-1">
						<span
							className="input-group-text pb-0"
							id="basic-addon1"
						>
							X2
						</span>
						<input
							className="form-control"
							type="text"
							id="X2"
							value={X2}
							onChange={(e) => setX2(e.target.value)}
							placeholder="Enter X2"
							required
						/>
					</div>
					<div className="input-group input-group-sm mb-1">
						<span
							className="input-group-text pb-0"
							id="basic-addon1"
						>
							Y2
						</span>

						<input
							className="form-control"
							type="text"
							id="Y2"
							value={Y2}
							onChange={(e) => setY2(e.target.value)}
							placeholder="Enter Y2"
							required
						/>
					</div>
				</div>

				<div className="form-group row">
					<textarea
						className="form-control input-group input-group-sm mb-0"
						id="description"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						placeholder="Description"
					/>
				</div>
				<div className="d-grid gap-4">
					<button className="btn btn-primary decorated-button shadow-lg  mb-3">
						Parcel Subdivide
					</button>
				</div>
			</form>
			{responseMessage && <p>{responseMessage}</p>}
		</div>
	);
});
export default SplitForm;
