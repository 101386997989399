import useDecodedUser from "../services/hooks/useDecodedUser";
import { CapitalizeWords } from "../utils/Formatting";
import { Grid, Typography } from "@mui/material";

export default function IPDCWelcomeMessage({ messageTitle }) {
  const decodedUser = useDecodedUser();
  return (
    <Grid item sm={12} md={10}>
      <Typography sx={{ fontSize: 36, fontWeight: 600, color: "black" }}>{messageTitle + " "}{decodedUser && decodedUser['role']} {" Dashboard"}</Typography>
      <Typography sx={{ fontSize: 12, fontWeight: 500, color: "#737373" }}>
        Welcome back, {decodedUser && CapitalizeWords(decodedUser["title"] + " " + decodedUser["full_name"])}
      </Typography>
    </Grid>
  );
}
