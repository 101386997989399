import React, {useState } from 'react';

const AssignGreenAreaForm = React.forwardRef((props, ref) => {
	const [id, setId] = useState('');
	const [name, setName] = useState('');
	const [no, setNo] = useState('');
	const [description, setDescription] = useState('');
	const [responseMessage, setResponseMessage] = useState('');

	const handleSubmit = async (e) => {
		e.preventDefault();
		props.clickResult();
	};

	return (
		<div className="form-section" style={{ width: '100%' }}>
			<form onSubmit={handleSubmit} ref={ref}>
				<div className="form-title">Green Area Info</div>
				<input
					className="form-control"
					type="text"
					id="id"
					value={id}
					onChange={(e) => setId(e.target.value)}
					placeholder="ID"
					required
				/>
				<input
					type="text"
					id="Name"
					value={name}
					onChange={(e) => setName(e.target.value)}
					placeholder="Name"
					required
				/>
				<input
					type="number"
					id="Number"
					value={no}
					onChange={(e) => setNo(e.target.value)}
					placeholder="Number"
					required
				/>
				<textarea
					id="description"
					value={description}
					onChange={(e) => setDescription(e.target.value)}
					placeholder="Description"
				/>
				<div className="d-grid gap-4">
					<button
						type="submit"
						className="btn btn-primary decorated-button shadow-lg"
					>
						Assign Info
					</button>
				</div>
			</form>
			{responseMessage && <p>{responseMessage}</p>}
		</div>
	);
});
export default AssignGreenAreaForm;
