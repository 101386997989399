import React, { useState, useCallback } from "react";
import { Box, Button, Grid } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { fromLonLat } from "ol/proj";

import ParkList from "../../../../Shared/PreRenderedComponents/ParkList";

export default function NavigateToPark({ center, setCenter }) {
  const [selectedPark, setSelectedPark] = useState(null);

  const handleGoToPark = useCallback(() => {
    if (selectedPark && selectedPark.center_of_park) {
      const newCenter = fromLonLat(selectedPark.center_of_park.coordinates);
      setCenter(newCenter);
      console.log("[center changed in navigate to park]", newCenter);
    }
  }, [selectedPark, setCenter]);

  return (
    <>
      <Grid container className="no-print" spacing={1}>
        <Grid item xs={7} md={7}>
          <ParkList selectedPark={selectedPark} setSelectedPark={setSelectedPark} />
        </Grid>
        <Grid item xs={5} md={5} alignContent={"center"} sx={{ display: "flex", justifyContent: "center" }}>
          <Button endIcon={<ArrowForwardIcon />} onClick={handleGoToPark} variant="contained" color="primary" fullWidth={true}>
            Go To Park
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
