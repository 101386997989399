import React, { useMemo } from "react";
import Map from "../Map/index.js";
import { Layers, TileLayer } from "../Layers/index.js";
import { NorthArrowControl } from "../Controls/index.js";
import { DEFAULT_CENTER, DEFAULT_ZOOM } from "../Util/Util.js";
import CommonLayers from "./CommonLayers";
import useMapData from "../hooks/useMapData.jsx";

//prettier-ignore
const DefaultMap = ({ controls, interactions, additionalLayers = [], center = DEFAULT_CENTER, zoom = DEFAULT_ZOOM,showPark = true, 
  showBlocks = true, showParcels = true, showSheds = true, showBuildings = true, showRoads = true, showGreenAreas = true, 
  showOtherInfras = false, showImporteds = false, showGCPS = false, showWasteDisposals = false, showWaters = false, showPowers = false, 
  showCommunications = false, showStorages = false, showSurveyDatas = false, showPlans = false, showSateliteImage = true, showUnaprovedImports=false,
  parcelsCategory = "OCCUP", isEditable = false, showNorth = false}) => {
    //prettier-ignore
  const {park,blocks, parcels, sheds, buildings, otherInfras, greenAreas, importeds, roads, gcps,
    wasteDisposals, waters, powers, communications, storages, surveyDatas, plans, unapprovedImports } = useMapData();

  const baseLayers = useMemo(
    () => <TileLayer label="OSM" title="osm" feature="OSM" zIndex={0} />,
    [],
  );
   return (
    <Map center={center} zoom={zoom}>
      <Layers>
        {
        //showNorth && <NorthArrowControl />
        }
        {showSateliteImage && baseLayers}
        {//prettier-ignore
          <CommonLayers park={showPark && park} blocks={showBlocks && blocks} parcels={showParcels && parcels} sheds={showSheds && sheds}
            parcelsCategory={parcelsCategory} otherInfras={showOtherInfras && otherInfras} greenAreas={showGreenAreas && greenAreas}
            importeds={showImporteds && importeds} roads={showRoads && roads} gcps={showGCPS && gcps}
            wasteDisposals={showWasteDisposals && wasteDisposals} waters={showWaters && waters} powers={showPowers && powers}
            communications={showCommunications && communications} storages={showStorages && storages}
            buildings={showBuildings && buildings} surveyDatas={showSurveyDatas && surveyDatas} plans={showPlans && plans} 
            unapprovedImports={showUnaprovedImports && unapprovedImports}
            isEditable={isEditable}
          />
        }

        {additionalLayers.map((layer, index) => (<React.Fragment key={index}>{layer}</React.Fragment> ))}
      </Layers>
      {controls}
      {interactions}
    </Map>
  );
};

export default DefaultMap;
