//================== GETTING GEOJSON FILE ON DB TO BE CORRECTLY PROJECTED
import { vector } from "../Source";
import { Style, Text, Fill, Stroke } from "ol/style";

import GeoJSON from "ol/format/GeoJSON";
import { fromLonLat, get } from "ol/proj";
//-------------------'FROM 'EPSG:4326' TO "EPSG:3857" FOR OPENLAYERS
const getProjectedVector = (unProjected) => {
  //console.log(unProjected)
  return vector({
    features: new GeoJSON().readFeatures(unProjected, {
      featureProjection: get("EPSG:3857"),
      dataProjection: "EPSG:4326",
    }),
  });
};
const getProjectedFromAdindan = (unProjected) => {
  //console.log("park boundary with adindan")
  //console.log(unProjected)
  return vector({
    features: new GeoJSON().readFeatures(unProjected,{
      featureProjection: get("EPSG:3857"),
      dataProjection: "EPSG:4326",
    }),
  });
};
const getVectorUnaltered = (unProjected) => {
  return vector({
    features: new GeoJSON().readFeatures(unProjected),
  });
};
//================== END GETTING GEOJSON FILE ON DB TO BE CORRECTLY PROJECTED
//================== GETTING STYLING TO DISPLAY TEXT OR NOT AND WHAT TO DISPLAY

const getSelectedParcelStyle = (feature, showText = true) => {
  try {
    const occupancyStatus = feature.get("occupancy_status");
    const upin = feature.get("upin");
    const txtFormat = new Text({
      font: 'bold 12px "open sans", "Arial Unicode Ms","sans-serif"',
      placement: "point",
      fill: new Fill({ color: occupancyStatus === "Vacant" ? "white" : "yellow" }),
      text: upin,
    });
    const stl = new Style({
      fill: occupancyStatus === "Vacant" ? new Fill({ color: "orange" }) : new Fill({ color: "Green" }),
      stroke: new Stroke({ color: "#888c8f", width: 1 }),
      text: showText ? txtFormat : null,
    });
    return stl;
  } catch (err) {
    console.log(err);
  }
};
const getSelectedStyle = (styleFormat, showText = false, fieldToDisplay = "id") => {
  if (showText)
    return function (feature) {
      styleFormat.getText().setText(feature.get(fieldToDisplay));
      return styleFormat;
    };
  else return styleFormat;
};
//================== END GETTING STYLING TO DISPLAY TEXT OR NOT AND WHAT TO DISPLAY
//================== GET MAP CETNER and DEFAULT MAP ZOOM LEVEL
const DEFAULT_CENTER = fromLonLat([38.856833, 8.969648]);
const DEFAULT_ZOOM = 16;
//===================END GET DEFAULT MAP ZOOM LEVEL
export {
  getProjectedVector,
  getProjectedFromAdindan,
  getVectorUnaltered,
  getSelectedParcelStyle,
  getSelectedStyle,
  DEFAULT_CENTER,
  DEFAULT_ZOOM,
  //getDefaultCenter
};
