import React, { useRef, useState, useEffect } from "react";
import "./Map.css";
import MapContext from "./MapContext";
import * as ol from "ol";
import { Select } from "ol/interaction";

const Map = ({ children, zoom, center }) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [mapData, setMapData] = useState({
    zoom: zoom,
    center: center,
    selectedFeatures: [],
    visibleLayers: [],
    extent: null,
  });

  // Initialize map on component mount
  useEffect(() => {
    let options = {
      view: new ol.View({ zoom, center }),
      layers: [],
      controls: [],
      overlays: [],
    };

    let mapObject = new ol.Map(options);
    mapObject.setTarget(mapRef.current);

    // Add map change listeners
    mapObject.on("moveend", () => updateMapData(mapObject));
    mapObject.on("changeLayer", () => updateMapData(mapObject));

    setMap(mapObject);

    return () => {
      mapObject.setTarget(undefined);
      mapObject.un("moveend", () => updateMapData(mapObject));
      mapObject.un("changeLayer", () => updateMapData(mapObject));
    };
  }, []);

  // Update map when zoom changes
  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(zoom);
  }, [zoom]);

  // Update map when center changes
  useEffect(() => {
    if (!map) return;
    map.getView().setCenter(center);
  }, [center]);

  // Function to update map data
  const updateMapData = mapObject => {
    if (!mapObject) return;

    const view = mapObject.getView();
    const layers = mapObject.getLayers().getArray();
    const extent = view.calculateExtent(mapObject.getSize());

    setMapData({
      zoom: view.getZoom(),
      center: view.getCenter(),
      selectedFeatures: getSelectedFeatures(mapObject),
      visibleLayers: getVisibleLayers(layers),
      extent: extent,
    });
  };

  // Helper functions to get map information
  const getSelectedFeatures = mapObject => {
    const features = [];
    mapObject.getInteractions().forEach(interaction => {
      if (interaction instanceof Select) {
        features.push(...interaction.getFeatures().getArray());
      }
    });
    return features;
  };

  const getVisibleLayers = layers => {
    return layers
      .filter(layer => layer.getVisible())
      .map(layer => ({
        id: layer.get("id"),
        name: layer.get("name"),
        type: layer.get("type"),
        visible: layer.getVisible(),
        opacity: layer.getOpacity(),
      }));
  };

  const getCurrentExtent = () => {
    if (!map) return null;
    return map.getView().calculateExtent(map.getSize());
  };

  // Context value with all map-related functions and data
  const mapContextValue = {
    map,
    mapData,
    setMap,
    updateMapData: () => updateMapData(map),
    getSelectedFeatures: () => getSelectedFeatures(map),
    getVisibleLayers: () => getVisibleLayers(map?.getLayers().getArray() || []),
    getCurrentExtent,
  };

  return (
    <MapContext.Provider value={mapContextValue}>
      <div
        ref={mapRef}
        style={{
          width: "100%",
          height: "78%",
          padding: 0,
          margin: 0,
          position: "fixed",
        }}
      >
        {children}
      </div>
    </MapContext.Provider>
  );
};

export default Map;
