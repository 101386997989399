import React, { useState, useEffect, useRef } from "react";
import NavigateToPark from "./NavigateToPark";
import { Interactions, SelectInteraction, ModifyInteraction } from "../Interactions";
import DefaultMap from "./DefaultMap";
import MapDataProvider from "../MapData/MapDataProvider";
import ShowSpatialFeatureDialog from "../ShowSpatialFeatureDialog";
import { DEFAULT_CENTER } from "../Util/Util";
import { Grid } from "@mui/material";
import GetControls from "./ShowControlsAndInteractions/GetControls";

//prettier-ignore
const ViewMap = ({ isSelectable = true, isEditable = false, showLegend = true, showPopup = true, additionalLayers = [],
                    additionalInteraction, setSelectedFeatureOnMap, showSateliteImage = true}) => {
  const [center, setCenter] = useState(DEFAULT_CENTER);
  const [selectedFeature, setSelectedFeature] = useState(null);
  //for selection change
  const [dialogKey, setDialogKey] = useState(0);
  const previousFeatureIdRef = useRef(null);

  useEffect(() => {
    const currentFeatureId = selectedFeature?.[0]?.getId();
    if (currentFeatureId !== previousFeatureIdRef.current) {
      setDialogKey((prevKey) => prevKey + 1);
      previousFeatureIdRef.current = currentFeatureId;
    }
    if (selectedFeature && setSelectedFeatureOnMap) setSelectedFeatureOnMap(selectedFeature);
  }, [selectedFeature]);

  const controls = <GetControls showLegend={showLegend} />;

  const interactions = (
    <Interactions>
      {isSelectable && <SelectInteraction setSelectedFeature={setSelectedFeature} showPopup={showPopup} />}
      {isEditable && <ModifyInteraction />}
      {additionalInteraction}
    </Interactions>
  );

  return (
    <>
      <MapDataProvider>
        <Grid container >
          <Grid item xs={6}>
        <NavigateToPark center={center} setCenter={setCenter} /></Grid>
        </Grid>

        <DefaultMap
          controls={controls}
          interactions={interactions}
          additionalLayers={additionalLayers}
          center={center}
          showPark={true}
          showBlocks={true}
          showParcels={true}
          showBuildings={true}
          showSheds={true}
          showRoads={true}
          showGreenAreas={true}
          isEditable={isEditable}
          showSateliteImage={showSateliteImage}
        />

        {showPopup && selectedFeature && selectedFeature[0]?.getId() && (
          <ShowSpatialFeatureDialog
            key={dialogKey}
            spatialInfo={selectedFeature[0]?.getProperties()}
            setShowWhenReady={true}
          />
        )}
      </MapDataProvider>
    </>
  );
};

export default React.memo(ViewMap);
