import React, { useState } from "react";
import { Paper, Box, Table, TableContainer } from "@mui/material";
import IPDCPrintHeader from "./IPDCPrintHeader";
import SearchAndFilter from "./Table/SearchAndFilter";
import TableHeader from "./Table/TableHeader";
import TableBodyComponent from "./Table/TableBodyComponent";
import TablePaginationComponent from "./Table/TablePaginationComponent";

const IPDCFinalTable = React.forwardRef(
  (
    {
      data,
      setData,
      title,
      selectedColumns,
      isEditable = false,
      isDeletable = false,
      isSearchable = true,
      isMessage = false,
      showHeader = true,
      hasAttachment = false,
      attachmentLink = "",
      columnsToExclude = [],
      targetPoint='',
      deleteKey='',
      ...rest
    },
    ref,
  ) => {
    const [editingRowIndex, setEditingRowIndex] = useState(null);
    const [editingData, setEditingData] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [filterColumn, setFilterColumn] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const filterAndSearchData = () => {
      if (!data || data.length === 0) return [];
      if (!searchQuery && !filterColumn) return data;

      return data.filter(row =>
        filterColumn
          ? String(row[filterColumn]).toLowerCase().includes(searchQuery.toLowerCase())
          : selectedColumns.some(column => String(row[column]).toLowerCase().includes(searchQuery.toLowerCase())),
      );
    };

    const handleChangePage = (event, newPage) => setPage(newPage);
    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const filteredData = filterAndSearchData();
    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
      <Paper ref={ref} style={{ width: "100%", overflowX: "auto" }}>
        <Box p={2}>
          {showHeader && <IPDCPrintHeader title={title} />}
          {isSearchable && (
            <SearchAndFilter
              columns={Object.keys(data[0])}
              searchQuery={searchQuery}
              filterColumn={filterColumn}
              setSearchQuery={setSearchQuery}
              setFilterColumn={setFilterColumn}
            />
          )}
          <TableContainer>
            <Table stickyHeader>
              <TableHeader
                selectedColumns={selectedColumns}
                columns={Object.keys(data[0])}
                isMessage={isMessage}
                hasAttachment={hasAttachment}
                showActions={isEditable || isDeletable}
              />
              <TableBodyComponent
                data={paginatedData}
                columns={Object.keys(data[0])}
                selectedColumns={selectedColumns}
                editingRowIndex={editingRowIndex}
                setEditingRowIndex={setEditingRowIndex}
                editingData={editingData}
                setEditingData={setEditingData}
                setData={setData}
                isEditable={isEditable}
                isDeletable={isDeletable}
                isMessage={isMessage}
                targetPoint={targetPoint}
                deleteKey={deleteKey}
                hasAttachment={hasAttachment}
                attachmentLink={attachmentLink}
              />
            </Table>
          </TableContainer>
          <TablePaginationComponent
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
    );
  },
);

export default IPDCFinalTable;

/*import React, { useState } from "react";
//prettier-ignore
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, TextField, FormControl, Select, MenuItem, Box, TablePagination, Grid, Checkbox } from "@mui/material";
import { styled } from "@mui/system";
//prettier-ignore
import { Edit as EditIcon, Save as SaveIcon, Cancel as CancelIcon, Attachment as AttachmentIcon } from "@mui/icons-material";
import config from "../config";
import IPDCPrintHeader from "./IPDCPrintHeader";
import { UpdateAndGetResponse } from "../services/api/ExecuteApiRequests";
import { IPDCConfirmDeletion } from "./Controls";

//prettier-ignore
const StyledTableCell = styled(TableCell)(({ theme }) => ({ fontWeight: "bold", textAlign: "center", padding: "8px",
      whiteSpace: "normal", overflow: "hidden", textOverflow: "ellipsis", wordWrap: "break-word", maxHeight: "4.5em", lineHeight: "1.5em" }));
//prettier-ignore
const StyledTableHeadCell = styled(StyledTableCell)(({ theme }) => ({ backgroundColor: "#42a147",color: "white",}));

//prettier-ignore
const IPDCFinalTable = React.forwardRef(
  ({data, setData, title, selectedColumns, isEditable = false, isDeletable = false,isSearchable = true, 
    isMessage = false, showHeader = true, hasAttachment = false, attachmentLink = "", ...rest}, ref,) => {
    const [editingRowIndex, setEditingRowIndex] = useState(null);
    const [editingData, setEditingData] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [filterColumn, setFilterColumn] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const downloadFile = async (url) => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${config.apiUrl}/${url}`, {
          method: 'GET',
          headers: {'Authorization': `Bearer ${token}`},
        });
    
        if (!response.ok) { throw new Error('Failed to fetch the file'); }
    
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', ''); // Optional: set a file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl); // Clean up
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    };
    

    const handleEditClick = (rowIndex) => {
      try {
        setEditingRowIndex(rowIndex);
        setEditingData(data[rowIndex]);
      } catch (error) {
        console.log(error);
      }
    };

    const handleCancelClick = () => {
      setEditingRowIndex(null);
      setEditingData({});
    };

    const handleSaveClick = () => {
      try {
        const updatedData = [...data];
        updatedData[editingRowIndex] = editingData;
        setData(updatedData);
        setEditingRowIndex(null);
        setEditingData({});
      } catch (error) {
        console.log(error);
      }
    };

    const handleDeleteClick = (rowIndex) => {
      try {
        if (rowIndex >= 0 && rowIndex < data.length) {
          const updatedData = data.filter((_, index) => index !== rowIndex);
          setData(updatedData);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleInputChange = (e, column) => {
      try {
        setEditingData({
          ...editingData,
          [column]: e.target.value,
        });
      } catch (error) {
        console.log(error);
      }
    };

    const handleSearchChange = (e) => {
      try {
        setSearchQuery(e.target.value);
      } catch (error) {
        console.log(error);
      }
    };

    const handleFilterChange = (e) => {
      try {
        setFilterColumn(e.target.value);
      } catch (error) {
        console.log(error);
      }
    };

    const filterAndSearchData = () => {
      try {
        //   if (!data || data.length === 0) return [];
        // if (!searchQuery && !filterColumn) return data;

        return data?.filter((row) => {
          if (filterColumn) {
            return String(row[filterColumn])?.toLowerCase()?.includes(searchQuery?.toLowerCase());
          } else {
            return selectedColumns?.some((column) =>
              String(row[column])?.toLowerCase()?.includes(searchQuery.toLowerCase()),
            );
          }
        });
      } catch (error) {
        console.log(error);
        return [];
      }
    };

    const handleMessageCheckboxChange = async (event, rowIndex) => {
      try {
        const updatedData = [...data];
        const message = updatedData[rowIndex];
        message.status = event.target.checked ? "Seen" : "Pending";
        setData(updatedData);
        const responseData = await UpdateAndGetResponse(`messages/${message.no}/updateStatus`, {
          msg_id: message.no,
          status: message.status,
        });
        console.log(responseData);
      } catch (err) {
        console.log(err);
      }
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      try {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      } catch (error) {
        console.log(error);
      }
    };

    if (!data || data.length === 0) return <div>No data</div>;
    const columns = Object.keys(data[0]);
    const filteredData = filterAndSearchData();
    const paginatedData = filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const formatColumnTitle = (column) => {
      return column?.replace(/_/g, " ")?.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const visibleColumns = columns?.filter(column => {
      if (hasAttachment && column === attachmentLink) {
        return false;
      }
      return selectedColumns?.includes(column);
    });

    return (
      <Paper ref={ref} style={{ width: "100%", overflowX: "auto" }}>
        <Box p={2}>
          {showHeader && <IPDCPrintHeader title={title} />}
          {isSearchable && (
            <Box mb={1} display="flex" justifyContent="space-between" className="no-print">
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Search"
                    variant="outlined"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    margin="normal"
                    style={{ marginRight: 16 }}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "none" }}>
                  <FormControl variant="outlined" margin="normal">
                    <Select fullWidth={true} value={filterColumn} onChange={handleFilterChange} label="Filter Column">
                      {columns &&
                        columns.length > 0 && columns.map((column) => (
                        <MenuItem key={column} value={column}>
                          {formatColumnTitle(column)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          )}
          <TableContainer style={{ maxHeight: "none", width: "100%" }}>
            <Table stickyHeader style={{ tableLayout: "fixed", width: "100%" }}>
              <TableHead>
                <TableRow>
                  {isMessage && <StyledTableHeadCell>Seen</StyledTableHeadCell>}
                  {columns &&
                    columns.length > 0 &&
                    columns
                      ?.filter((column) => selectedColumns?.includes(column))
                      ?.map((column) => (
                        <StyledTableHeadCell key={column}>{formatColumnTitle(column)}</StyledTableHeadCell>
                      ))}
                  {hasAttachment && <StyledTableHeadCell className="no-print">Attachment</StyledTableHeadCell>}
                  {(isEditable || isDeletable) && (
                    <StyledTableHeadCell className="no-print">Actions</StyledTableHeadCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData && paginatedData.length > 0 && paginatedData.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={rowIndex % 2 ? { backgroundColor: "#f2f2f2" } : { backgroundColor: "#ffffff" }}
                  >
                    {isMessage && (
                      <StyledTableCell>
                        <Checkbox
                          checked={row.status === "Seen"}
                          onChange={(e) => handleMessageCheckboxChange(e, rowIndex)}
                        />
                      </StyledTableCell>
                    )}
                    {columns &&
                      columns.length > 0 &&
                      columns
                        ?.filter((column) => selectedColumns?.includes(column))
                        ?.map((column) => (
                          <StyledTableCell key={column}>
                            {editingRowIndex === rowIndex ? (
                              <TextField value={editingData[column]} onChange={(e) => handleInputChange(e, column)} />
                            ) : (
                              row[column]
                            )}
                          </StyledTableCell>
                        ))}
                    {hasAttachment && (
                      <StyledTableCell className="no-print">
                        {row[attachmentLink] && row[attachmentLink]?.length > 0 && (
                          <IconButton onClick={() => downloadFile(row[attachmentLink])}>
                            <AttachmentIcon />
                          </IconButton>
                        )}
                      </StyledTableCell>
                    )}
                    {(isEditable || isDeletable) && (
                      <StyledTableCell className="no-print">
                        {editingRowIndex === rowIndex ? (
                          <>
                            <IconButton onClick={handleSaveClick}>
                              <SaveIcon />
                            </IconButton>
                            <IconButton onClick={handleCancelClick}>
                              <CancelIcon />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            {isEditable && (
                              <IconButton onClick={() => handleEditClick(rowIndex)}>
                                <EditIcon />
                              </IconButton>
                            )}
                            { isDeletable && (<IPDCConfirmDeletion onConfirm={() => handleDeleteClick(rowIndex)} onCancel={() => {}} />) }
                          </>
                        )}
                      </StyledTableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box align="left" sx={{ marginTop: "16px" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Box>
      </Paper>
    );
  },
);

export default IPDCFinalTable;
*/
