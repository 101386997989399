import { Style, Fill, Stroke, Circle as CircleStyle, RegularShape, Text } from "ol/style";

/**
 * Style function to style OpenLayers features based on shape type and other attributes.
 * @param {Object} feature - The feature to style.
 * @param {string} shapeType - The type of shape to apply (e.g., 'Point', 'Line', 'Polygon').
 * @param {boolean} showText - Flag to indicate if text should be shown.
 * @param {string} textFieldToShow - The field to display as text on the feature.
 * @param {string} textColor - The color of the text.
 * @param {string|null} shapeColor - The color of the shape. If null, uses `StringToColor`.
 * @param {number} opacity - The opacity level (0 to 1) for the fill and line colors.
 * @returns {Style} The style for the feature.
 */
// prettier-ignore
function getNonParcelFeatureStyle(feature, shapeColor = null, showText = true, textFieldToShow = "id", textColor = "white", opacity = 0.75, polygonBorder="grey") {
  // Determine colors based on `shapeColor` or `StringToColor` function
  const color = shapeColor || StringToColor(feature?.get("feature"));
  const shapeType = feature.getGeometry().getType();
  const fillColor = color;
  const lineColor = color;

  // Text style if `showText` is true
  const text = showText ? getStyledText(feature, textFieldToShow, textColor) : null;
  /*new Text({
        text: feature.get(textFieldToShow) ? String(feature.get(textFieldToShow)) : "",
        fill: new Fill({ color: textColor }),
        stroke: new Stroke({ color: "black", width: 1 }),
        font: "12px Calibri,sans-serif",
      })
    : null;*/

  // Define style based on `shapeType`
  switch (shapeType) {
    case "Point":
      // Different shapes for points
      const pointShape = feature.get("pointShape") || "circle"; // example shape: 'circle', 'square'
      if (pointShape === "square") {
        return new Style({
          image: new RegularShape({
            fill: new Fill({ color: fillColor }),
            stroke: new Stroke({ color: polygonBorder, width: 2 }),
            points: 4,
            radius: 6,
            angle: Math.PI / 4, // rotate to make a square
          }),
          text: text,
        });
      } else {
        return new Style({
          image: new CircleStyle({
            fill: new Fill({ color: fillColor }),
            stroke: new Stroke({ color: lineColor, width: 2 }),
            radius: 6,
          }),
          text: text,
        });
      }

    case "LineString":
      return new Style({
        stroke: new Stroke({color: lineColor, width: 3}),
        text: text,
      });

    case "Polygon":
      return new Style({
        fill: new Fill({ color: fillColor, }),
        stroke: new Stroke({ color: lineColor, width: 2, }),
        text: text,
      });

    default:
      // Default style if `shapeType` is unknown
      return new Style({
        stroke: new Stroke({ color: "gray", width: 1 }),
        text: text,
      });
  }
}

// Example of `StringToColor` function
function StringToColor(inputString) {
  // This function generates a color based on the input string (e.g., feature property)
  // Here, we return a simple hash-based color.
  let hash = 0;
  for (let i = 0; i < inputString.length; i++) {
    hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `#${((hash >> 24) & 0xff).toString(16).padStart(2, "0")}${((hash >> 16) & 0xff)
    .toString(16)
    .padStart(2, "0")}${((hash >> 8) & 0xff).toString(16).padStart(2, "0")}`;
  return color;
}
const getStyledText = (feature, textToDisplay, textColor = "white") => {
  const isWide = isFeatureWide(feature);
  try {
    const fontSize = isWide ? 12 : 8; // Adjust font size based on width
    const padding = isWide ? 1 : 0.5; // Adjust padding based on width

    return new Text({
      font: `bold ${fontSize}px Calibri,sans-serif`,
      placement: "point",
      fill: new Fill({ color: textColor }),
      text: feature.get(textToDisplay) ? String(feature.get(textToDisplay)) : "",
      rotation: isWide ? 0 : Math.PI / 2, // Rotate 90 degrees for tall features
      textAlign: "center",
      stroke: new Stroke({ color: "black", width: 1 }),
      offsetX: padding, // Add offset to create padding
      offsetY: padding, // Add offset to create padding
    });
  } catch (error) {
    console.log(error);
    return new Style({
      stroke: new Stroke({ color: "gray", width: 1 }),
      text: textToDisplay,
    });
  }
};
const isFeatureWide = (feature) => {
  let isWide = false;
  try {
    const geometry = feature.getGeometry();
    const extent = geometry.getExtent();
    const width = extent[2] - extent[0];
    const height = extent[3] - extent[1];
    isWide = width > height;
    return isWide;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export { getNonParcelFeatureStyle };
