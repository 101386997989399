import React, { useEffect, useState, useCallback } from "react";
import {
  AssignParkForm,
  AssignBlockForm,
  AssignParcelForm,
  AssignInfrastructureForm,
  AssignGreenAreaForm,
  AssignShedForm,
  AsignInfoToOthersForm,
} from "./Forms";
import ViewMap from "../../MapRelated/Views/ViewMap";

import { Box, Container, Paper, Typography } from "@mui/material";

const featureTypes = [
  { key: "PARK", label: "Park" },
  { key: "BLOCK", label: "Block" },
  { key: "PARCEL", label: "Parcel" },
  { key: "GREEN", label: "Green Area" },
  { key: "ROAD", label: "Road" },
  { key: "GPX", label: "GPS exchange format" },
  { key: "GCP", label: "Ground Control Point" },
  { key: "Power", label: "Power" },
  { key: "Communication", label: "Communication" },
  { key: "Water", label: "Water" },
  { key: "INFRA", label: "Other Infrastructure" },
  { key: "waste", label: "Waste Disposal" },
  { key: "OTHERS", label: "Other" },
];

export default function AssignInfoToMap() {
  const [selectedFeatureOnMap, setSelectedFeatureOnMap] = useState(null);
  const [selectedFeatureAttributeInfo, setSelectedFeatureAttributeInfo] =
    useState(null);

  const [selectedFeatureType, setSelectedFeatureType] = useState(null);
  //const [featureType, setFeatureType] = useState(featureTypes[0].key);
  const [formToDisplay, setFormToDisplay] = useState(null);

  const updateFeatureInfo = useCallback((feature) => {
    if (!feature) return;
    const { geometry, ...attributes } = feature[0]?.getProperties();
    setSelectedFeatureType(attributes.feature);
    setSelectedFeatureAttributeInfo(feature[0]?.getProperties());
  }, []);

  useEffect(() => {
    updateFeatureInfo(selectedFeatureOnMap);
  }, [selectedFeatureOnMap, updateFeatureInfo]);

  const changeFormsBasedOnChoice = useCallback(() => {
    const info = selectedFeatureAttributeInfo;
    console.log("Changed info: \n", info);

    switch (selectedFeatureType) {
      case "PARK":
        setFormToDisplay(<AssignParkForm featureInfo={info} />);
        break;
      case "BLOCK":
        setFormToDisplay(<AssignBlockForm featureInfo={info} />);
        break;
      case "PARCEL":
        setFormToDisplay(<AssignParcelForm featureInfo={info} />);
        break;
      case "INFRA":
      case "ROAD":
        setFormToDisplay(<AssignInfrastructureForm featureInfo={info} />);
        break;
      case "GREEN":
        setFormToDisplay(<AssignGreenAreaForm featureInfo={info} />);
        break;
      case "SHED":
        setFormToDisplay(<AssignShedForm featureInfo={info} />);
        break;
      case "OTHER":
        setFormToDisplay(<AsignInfoToOthersForm featureInfo={info} />);
        break;
      default:
        if (formToDisplay) {
          setFormToDisplay(<AsignInfoToOthersForm featureInfo={info} />);
        }
    }
  }, [selectedFeatureType, selectedFeatureAttributeInfo, formToDisplay]);

  useEffect(() => {
    changeFormsBasedOnChoice();
  }, [changeFormsBasedOnChoice]);

  return (
    <Container sx={{ position: "relative" }}>
      <Box sx={{ height: "60%", display: "flex", flexDirection: "column" }}>
        <Typography variant="h6" gutterBottom>
          Assign Info To Map
        </Typography>
        <div>
          <div className="floating-form shadow-lg">
            <div>
              <select
                className="form-select mb-0"
                id="featureType"
                value={selectedFeatureType}
                style={{ marginLeft: "20px" }}
                onChange={(event) => setSelectedFeatureType(event.target.value)}
              >
                {featureTypes.map((type) => (
                  <option key={type.key} value={type.key}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
            <Paper
              elevation={3}
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                mb: 2,
              }}
            >
              {formToDisplay && (
                <Box sx={{ p: 2, overflowY: "auto", flex: 1 }}>
                  {formToDisplay}
                </Box>
              )}
            </Paper>
          </div>
        </div>
      </Box>
      <Box sx={{ position: "relative", height: "100vh" }}>
        <ViewMap
          showPopup={false}
          setSelectedFeatureOnMap={setSelectedFeatureOnMap}
          showLegend={true}
        />
      </Box>
    </Container>
  );
}
