import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let isToastDisplayed = false;
// Centralized error handling component
const NetworkErrorHandler = ({ children }) => {
  //const axiosInstance = axios.create({
  //timeout: 10000, // 10 seconds timeout
  //});
  axios.interceptors.response.use(
    response => {
      // Reset the toast flag after a successful response
      isToastDisplayed = false;
      return response;
    },
    error => {
      if (!isToastDisplayed) {
        isToastDisplayed = true;

        let errorMessage = "An unknown error occurred. Please try again later.";

        if (error.code === "ECONNABORTED") {
          errorMessage = "The server is taking too long to respond. Please try again later.";
        } else if (error.code === "ERR_NETWORK") {
          errorMessage = "Unable to access the server. Please check your network connection.";
        } else if (error.response) {
          // Handle server-side errors (status code >= 400)
          errorMessage = error.response.data?.message || `Server error: ${error.response.status}`;
        }

        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          onClose: () => {
            // Reset the flag when the toast is closed
            isToastDisplayed = false;
          },
        });
      }
      return Promise.reject(error);
    },
  );
  axios.interceptors.request.use(
    config => {
      //console.log("success : toast displaied", isToastDisplayed);
      isToastDisplayed = false;
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      // Handle errors during the request setup
      if (!isToastDisplayed) {
        isToastDisplayed = true;
        toast.error("Error in setting up the request. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          onClose: () => {
            // Reset the flag when the toast is closed
            isToastDisplayed = false;
          },
        });
      }
      return Promise.reject(error);
    },
  );

  return (
    <>
      <ToastContainer />
      {children}
    </>
  );
};
export default NetworkErrorHandler;
//export { axiosInstance };
