import { FormattedReport } from "../../../Shared/Report";

const department = "gis";
const reports = [
  { access: "ratioSummary", title: "Park Ratio Summary" },
  { access: "buiding", title: "Building Ratio" },
  { access: "parcel", title: "Parcel Ratio" }, //const {startDate,endDate}=req.params
  { access: "occupancy", title: "Occupancy Ratio" },
  { access: "zoneRatio", title: "Zone Ratio" },
  { access: "industryRation", title: "Industry Ratio" },
  { access: "shedVSParcelDevelopment", title: "Shed Vs Parcel" },
  { access: "roadRatioPerPark", title: "Road Ratio" },
  { access: "greenArea", title: "Green Area Ratio" },
  { access: "wasteTreatment", title: "Waste Treatment" }, //view_gis_waste_treatment
];
export default function GISReports() {
  return <FormattedReport reports={reports} department={department} />;
}
