import React, { useState, useEffect } from "react";
import { TextField, Grid } from "@mui/material";
import FormContainer from "../../../components/Forms/FormContainer";

import useDecodedUser from "../../../services/hooks/useDecodedUser";
import { ExecutePostWithParams } from "../../../services/api/ExecuteApiRequests";
import Logout from "../../Auth/Logout";
import { Navigate } from 'react-router-dom';
import { passwordRegex } from "../../../utils/SecurityRules";
import { getCurrentDeviceInfo, generateDeviceId } from "../../../utils/DeviceHelper";

const ChangePassword = () => {
  const decodedUser = useDecodedUser();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Generate or retrieve device ID from localStorage
    let deviceId = localStorage.getItem("device_id");
    if (!deviceId) {
      deviceId = generateDeviceId();
      localStorage.setItem("device_id", deviceId);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (newPassword === "" || confirmPassword === "") {
      setErrorMessage("Please fill in all required fields.");
      return; // Prevent further execution if fields are empty
    } else if (newPassword !== confirmPassword) {
      setErrorMessage("New passwords do not match.");
      return;
    } else if (currentPassword === "" && decodedUser.status === "active") {
      setErrorMessage("Please enter current password first.");
      return;
    } else if (!passwordRegex.test(newPassword)) {
      setErrorMessage(
        "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character.",
      );
      return;
    }

    try {
      const deviceInfo = getCurrentDeviceInfo(); // Get device information
      const result = await ExecutePostWithParams(`users/${decodedUser.id}/changePassword`, {
        userId: decodedUser.id,
        currentPW: currentPassword,
        newPW: newPassword,
        deviceInfo,
      });

      if (result) {
        console.log(result);
        //Logout();
        localStorage.removeItem('user-id');
        localStorage.removeItem('token');
        window.location.reload(false);
        window.location.replace('/');
        //setIsLoggedOut(true);
        
        await ExecutePostWithParams('users/logout');
        return <Navigate to="/" replace />;
        console.log("logged out:");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/*prettier-ignore*/}
      <FormContainer title={decodedUser.status === "active" ? "Change Password" : "Re/Set Password"} onSubmit={handleSubmit} buttonLabel="Change Password">
      <Grid item xs={3}>
        <TextField id="id" label="ID" type="text" fullWidth={true} value={decodedUser && decodedUser.id} disabled />
      </Grid>
      <Grid item xs={9}>
        {/*prettier-ignore*/}
        <TextField id="full_name" label="Full Name" type="text" fullWidth={true} value={decodedUser && decodedUser.full_name} disabled />
      </Grid>
      <Grid item xs={12}>
        {/*prettier-ignore*/}
        <TextField id="username" label="Username" type="text" fullWidth={true} value={decodedUser.username} required />
      </Grid>
      <Grid item xs={12}>
        {/*prettier-ignore*/}
        <TextField id="current_password" label="Current Password" type="text" inputProps={{ autocomplete: "new-password", form: { autocomplete: "off", autocorrect: "off"}, }}
          value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} fullWidth={true}
          disabled={decodedUser.status === "NEW" || decodedUser.status === "RESET" ? true : false}/>
      </Grid>
      <Grid item xs={12}>
        {/*prettier-ignore*/}
        <TextField id="new_password" label="New Password" type="text" fullWidth={true} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
      </Grid>
      <Grid item xs={12}>
        {/*prettier-ignore*/}
        <TextField id="confirm_password" label="Confirm New Password" type="password" fullWidth={true} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required/>
      </Grid>
      {errorMessage && (
        <Grid item xs={12}>
          <p style={{ color: "red" }}>{errorMessage}</p>
        </Grid>
      )}
    </FormContainer>
    </>
  );
};

export default ChangePassword;
