import React, {  useEffect, useState } from "react";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import ViewMap from "./ViewMap";

import { MergeForm, SplitForm } from "../../Forms";

import { DrawInteraction } from "../Interactions";
import { vector } from "../Source";
import { VectorLayer } from "../Layers";

import { newPlot } from "../Style/MapStyle";

const EditMap = () => {
  const [isEditable, setIsEditable] = useState(false);
  const [selectedFeatureOnMap, setSelectedFeatureOnMap] = useState(null);
  //const mapRef = useRef(null);
  //------------SHOW AND HIDE SPLIT AND MERGE FORM
  const [showSplitForm, setShowSplitForm] = useState(false);
  const [showMergeForm, setShowMergeForm] = useState(false);

  const [additionalLayerForPlot, setAdditionalLayerForPlot] = useState(null);
  const [additionalInteractionForPlot, setAdditionalInteractionForPlot] = useState(null);
  useEffect(() => {
    
  }, [isEditable]);

  const handleMergeButton = () => {
    setIsEditable(false);
    setShowMergeForm(!showMergeForm);
    if (showSplitForm) {
      setShowSplitForm(false);
    }
    console.log("show Merge form");
  };

  const handleSplitButton = () => {
    setIsEditable(false);
    setShowSplitForm(!showSplitForm);
    if (showMergeForm) {
      setShowMergeForm(false);
    }
    console.log("show split form");
  };
  //------------end show split and merge form
  useEffect(() => {
    console.log("additional plot and/or interaction added");
  }, [additionalLayerForPlot, additionalInteractionForPlot]);
  //==============DRAW FEATURES
  const drawFeatures = () => {
    setIsEditable(false);
    setShowMergeForm(false);
    setShowSplitForm(false);

    const source = new vector({ wrapX: false });
    const draw = <DrawInteraction source={source} type="Polygon" />;
    const v = <VectorLayer source={source} style={newPlot} zIndex={10} />;
    setAdditionalLayerForPlot(v);
    setAdditionalInteractionForPlot(draw);

    console.log(source);
    console.log("draw feartures clicked");
  };

  return (
    <>
      <Box>
        <ToggleButtonGroup exclusive sx={{ float: "right", position: "relative", right: 10 }}>
          <ToggleButton key={"Consolidate"} value={"Consolidate"} onClick={handleMergeButton}>
            {"Consolidate"}
          </ToggleButton>
          <ToggleButton key={"Subdivide"} value={"Subdivide"} onClick={handleSplitButton}>
            {"Subdivide"}
          </ToggleButton>
          <ToggleButton key={"Plot"} value={"Plot"} onClick={drawFeatures}>
            {"Plot"}
          </ToggleButton>
          <ToggleButton key={"Modify"} value={"Modify"} onClick={(e) => setIsEditable(true)}>
            {"Modify"}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {<ViewMap setSelectedFeatureOnMap={setSelectedFeatureOnMap} additionalLayers={[additionalLayerForPlot]} isEditable={isEditable} showLegend={true} showPopup={false}/>}
      {showSplitForm && <SplitForm />}
      {showMergeForm && <MergeForm selectedFeatureOnMap={selectedFeatureOnMap} />}
    </>
  );
};

export default React.memo(EditMap);
