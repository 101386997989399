import React, { useEffect, useState } from "react";
import "../../../../../styles/sample-form.css";
import { ExecuteApiToPost } from "../../../../../services/api/ExecuteApiRequests";

const MergeForm = React.forwardRef(({selectedFeatureOnMap},ref) => {
  const [upin1, setUpin1] = useState("");
  const [upin2, setUpin2] = useState("");
  const [description, setDescription] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
useEffect(()=>{
  console.log(selectedFeatureOnMap);
  if(selectedFeatureOnMap){
    setUpin1(selectedFeatureOnMap[0]?.get("upin")|| "")
    if(selectedFeatureOnMap[0]?.get("upin")===upin2) setUpin2(selectedFeatureOnMap[0]?.get("upin")|| "")
  }
},[selectedFeatureOnMap])
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("merge started");
      const parameterValues = {
        upin1: upin1,
        upin2: upin2,
        description: description,
      };
      const responseData = await ExecuteApiToPost("mergeParcel", parameterValues);
      console.log(responseData);
      if (responseData) {
        setResponseMessage("merge successful!");
        setUpin1("");
        setUpin2("");
        setDescription("");
        setResponseMessage("");
      } else {
        setResponseMessage("merge failed: " + responseData);
      }
      console.log("merge completed");
    } catch (err) {
      console.log(err);
    }
  };
  

  return (
    <div className="floating-form shadow-lg">
      <form onSubmit={handleSubmit} >
        <div className="form-title">Consolidate Parcels</div>
        <input
          className="form-control mb-3"
          type="text"
          id="upin1"
          value={upin1}
          onChange={(e) => setUpin1(e.target.value)}
          placeholder="UPIN 1"
          required
        />

        <input
          className="form-control  mb-3"
          type="text"
          id="upin2"
          value={upin2}
          onChange={(e) => setUpin2(e.target.value)}
          placeholder="UPIN 2"
          required
        />
        <textarea
          className="form-control  mb-3"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
        />
        <button type="submit" className="btn btn-primary decorated-button shadow-lg mb-3">
          Consolidate
        </button>
      </form>
      {responseMessage && <p>{responseMessage}</p>}
    </div>
  );
});
export default MergeForm;
