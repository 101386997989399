//deployment
  /**
module.exports = {
    apiUrl: "http://localhost:4000/api/v1",//process.env.REACT_APP_API_URI,
  };
  */
   module.exports = {
    apiUrl: 'https://lis.ipdc.gov.et/api/v1',
    baseUrl: 'https://lis.ipdc.gov.et',
  };
   