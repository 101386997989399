import React, { useEffect, useState } from "react";
import IPDCStylizedTextField from "../../../../../../../components/Controls/IPDCStylizedTextField";
import { TextField, Button, Typography, Box, Snackbar, Grid, Select, MenuItem } from "@mui/material";
import { UpdateAndGetResponse } from "../../../../../../../services/api/ExecuteApiRequests";

const types = [
  { key: "SHED", label: "SHED" },
  { key: "PARCEL", label: "PARCEL" },
];

const AssignParcelForm = ({ featureInfo }) => {
  const [formData, setFormData] = useState({
    localId: "",
    blockNo: "",
    upin: "",
    name: "",
    plannedFunction: "",
    currentFunction: "",
    description: "",
    type: "",
    noOfBuildings: "",
    noOfPlannedBuildings: "",
  });

  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  useEffect(() => {
    console.log(featureInfo);
    if (featureInfo) {
      const newFormData = {
        localId: featureInfo.local_shed_parcel_no || "",
        blockNo: featureInfo.block_no || "",
        upin: featureInfo.upin || "",
        name: featureInfo.name || "",
        //plannedFunction: featureInfo.plannedFunction||"",
        currentFunction: featureInfo.current_function || "",
        description: featureInfo.description || "",
        type: featureInfo.type || "",
        //noOfBuildings: featureInfo.noOfBuildings||0,
        //noOfPlannedBuildings: featureInfo.noOfPlannedBuildings||0,
      };
      console.log("Updating formData to:", newFormData);
      setFormData(newFormData);
    }
  }, [featureInfo]);
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await UpdateAndGetResponse(`parcels`, {
        upin: formData.upin,
        name: formData.name,
        planned_for: formData.plannedFunction,
        current_function: formData.currentFunction,
        type: formData.type,
        no_of_planned_buildings: formData.noOfPlannedBuildings,
        no_of_buildings: formData.noOfBuildings,
        description: formData.description,
      });
      if (response.status === 201) {
        setSnackbar({ open: true, message: "Registration successful!" });
      }
    } catch (error) {
      setSnackbar({ open: true, message: `Registration failed: ${error.message}` });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Parcel Info
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <IPDCStylizedTextField
            fullWidth={true}
            id="upin"
            label="UPIN"
            value={formData.upin}
            onChange={handleChange}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <IPDCStylizedTextField
            fullWidth={true}
            id="localId"
            label="Local Id/shed or parcel no/"
            value={formData.localId}
            onChange={handleChange}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <IPDCStylizedTextField
            fullWidth={true}
            id="blockNo"
            label="Block No"
            value={formData.blockNo}
            onChange={handleChange}
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <IPDCStylizedTextField
            fullWidth={true}
            id="name"
            label="Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <IPDCStylizedTextField
            fullWidth={true}
            id="plannedFunction"
            label="Planned Function"
            value={formData.plannedFunction}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <IPDCStylizedTextField
            fullWidth={true}
            id="currentFunction"
            label="Current Function"
            value={formData.currentFunction}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Select fullWidth={true} id="type" value={formData.type} onChange={handleChange} displayEmpty>
            <MenuItem value="" disabled>
              Select Parcel Type
            </MenuItem>
            {types.map((type) => (
              <MenuItem key={type.key} value={type.key}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <IPDCStylizedTextField
            fullWidth={true}
            type="number"
            id="noOfBuildings"
            label="Number of buildings"
            value={formData.noOfBuildings}
            onChange={handleChange}
            inputProps={{ min: 0 }}
          />
        </Grid>
        <Grid item xs={6}>
          <IPDCStylizedTextField
            fullWidth={true}
            type="number"
            id="noOfPlannedBuildings"
            label="Number of Planned buildings"
            value={formData.noOfPlannedBuildings}
            onChange={handleChange}
            inputProps={{ min: 0 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth={true}
            id="description"
            label="Description"
            value={formData.description}
            onChange={handleChange}
            multiline
            rows={2}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth={true}>
            Assign Info
          </Button>
        </Grid>
		
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default AssignParcelForm;
