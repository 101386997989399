import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Alert,
  CircularProgress,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import FormContainer from "../../../../../../components/Forms/FormContainer";
import useDecodedUser from "../../../../../../services/hooks/useDecodedUser";
import ParkList from "../../../../Shared/PreRenderedComponents/ParkList";
import { ExecutePostWithParams } from "../../../../../../services/api/ExecuteApiRequests";
import { SPATIAL_FEATURE_OPTIONS, FEATURE_CATEGORIES } from "../../../../../../constants/spatialFeatures";

const ImportExistingParkData = () => {
  const decodedUser = useDecodedUser();
  const [formData, setFormData] = useState({ selectedPark: null, category: "", layerName: "", file: null });
  const [status, setStatus] = useState({ loading: false, error: null, success: null });

  const validateForm = () => {
    const { selectedPark, category, layerName, file } = formData;
    if (!selectedPark) return "Please select a park";
    if (!category) return "Please select a category";
    if (!layerName) return "Please select a feature to import";
    if (!file) return "Please upload a file";
    return null;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      setStatus({ ...status, error: validationError });
      return;
    }

    setStatus({ loading: true, error: null, success: null });

    try {
      const submitData = new FormData();
      submitData.append("imported_by", decodedUser?.id);
      submitData.append("park_id", formData.selectedPark?.id);
      submitData.append("category", formData.category);
      submitData.append("layer_name", formData.layerName);
      submitData.append("file", formData.file);

      const target = "importInfrastructure";
      const response = await ExecutePostWithParams(target, submitData, true);

      setStatus({ loading: false, error: null, success: response.message || "Data imported successfully" });
    } catch (error) {
      setStatus({ loading: false, error: error.message || "Failed to import data", success: null });
    }
  };

  const handleInputChange = (field, value) => {
    console.log(field, value);
    setFormData((prev) => ({
      ...prev,
      [field]: value,
      ...(field === "category" && { layerName: "" }),
    }));
  };

  return (
    <>
      <Box position={"relative"} justifyContent="center" alignItems="center" width={"100%"}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Button  startIcon={<DeleteIcon />} endIcon={<EditIcon />} href="/editImportedData" >
                <Typography variant="h6">Show import history</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <FormContainer title="Import Data for Existing Park" onSubmit={handleSubmit}>
        {status.error && <Alert severity="error">{status.error}</Alert>}
        {status.success && <Alert severity="success">{status.success}</Alert>}

        <Grid item xs={12}>
          <ParkList
            selectedPark={formData.selectedPark}
            setSelectedPark={(park) => handleInputChange("selectedPark", park)}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Select Category</InputLabel>
            <Select value={formData.category} onChange={(e) => handleInputChange("category", e.target.value)} required>
              <MenuItem value="">Select Category</MenuItem>
              {FEATURE_CATEGORIES.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth disabled={!formData.category}>
            <InputLabel>Select Feature to Import</InputLabel>
            <Select
              value={formData.layerName}
              onChange={(e) => handleInputChange("layerName", e.target.value)}
              required
            >
              {SPATIAL_FEATURE_OPTIONS[formData.category]?.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <InputLabel htmlFor="fileUpload">Upload Zipped Shape File (zip):</InputLabel>
          <input
            type="file"
            id="fileUpload"
            name="fileUpload"
            accept=".zip"
            onChange={(e) => handleInputChange("file", e.target.files[0])}
            required
          />
        </Grid>
        {status.loading && (
          <Grid item justifyContent="center" style={{ marginTop: 16 }}>
            <CircularProgress />
          </Grid>
        )}
      </FormContainer>
    </>
  );
};

export default ImportExistingParkData;
