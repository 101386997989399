import React, { useState, useEffect } from "react";
//prettier-ignore
import {
  Container, Grid, TextField, Button, Select, MenuItem, FormControl, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar
} from "@mui/material";
import FormContainer from "../../../../components/Forms/FormContainer";

import { GetUsers } from "../../Shared/CommonData/CommonData";
import config from "../../../../config";
import { UpdateAndGetResponse } from "../../../../services/api/ExecuteApiRequests";
import { IPDCToastMessageResult } from "../../../../components/Controls";

const ActivateOrDeactivateAccount = ({ title, onSubmit }) => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const [toastMessage, setToastMessage] = useState(null); // Renamed for clarity

  useEffect(() => {
    // Fetch users from API
    const fetchUsers = async () => {
      const extractedUsers = await GetUsers();
      setUsers(extractedUsers);
    };
    fetchUsers();
  }, []);

  const handleStatusToggle = async userId => {
    try {
      const formData = new FormData();
      const result = await UpdateAndGetResponse(`users/${userId}/activateDeactivate`, formData);
      //console.log(result);
      if (result?.length > 0) {
        return;
      } else {
        const updatedUsers = users?.map(user =>
          user?.id === userId
            ? {
                ...user,
                status:
                  user?.status?.toLowerCase() === "new"
                    ? "RESET"
                    : user?.status?.toLowerCase() === "active"
                    ? "inactive"
                    : "active",
              }
            : user,
        );

        setUsers(updatedUsers);
        const currentUser = users.find(user => user.id === userId);
        //const successMessage = ;
        setToastMessage({
          message: "User account status changed!",
          type: currentUser?.status?.toLowerCase() === "active" ? "warning" : "success",
        });
      }
    } catch (error) {
      console.log(error);
      setToastMessage({ message: "Error occured while altering the user account, please try again.", type: "error" }); // Set toast message
    } finally {
      // Added finally block to ensure toast message is cleared after a certain time
      setTimeout(() => {
        setToastMessage(null);
      }, 3000); // Clear toast message after 3 seconds
    }
  };

  const filteredUsers = users?.filter(
    user =>
      (statusFilter ? user?.status === statusFilter : true) &&
      (searchTerm
        ? Object?.values(user)?.some(value => value?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
        : true),
  );

  return (
    <Container>
      <FormContainer title={title} onSubmit={onSubmit} showButton={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth={true}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth={true} variant="outlined">
              <InputLabel>Status Filter</InputLabel>
              <Select value={statusFilter} onChange={e => setStatusFilter(e.target.value)} label="Status Filter">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="NEW">New</MenuItem>
                <MenuItem value="RESET">Reset</MenuItem>
                <MenuItem value="inactive">Inactive/Deactivated</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Full Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers?.map(user => (
                    <TableRow key={user.id + user.full_name}>
                      <TableCell>
                        <Avatar
                          src={`${config.apiUrl}/uploads/profilePicture/${encodeURIComponent(
                            user?.profile_picture?.split("/")?.pop(),
                          )}`}
                          alt={user.full_name}
                        />
                      </TableCell>
                      <TableCell>{user?.id}</TableCell>
                      <TableCell>{user?.full_name}</TableCell>
                      <TableCell>{user?.email}</TableCell>
                      <TableCell sx={{ color: user?.status?.toLowerCase() === "active" ? "green" : "red" }}>
                        {user?.status}
                      </TableCell>
                      <TableCell>
                        {user && user?.status !== "RESET" && (
                          <Button
                            variant="contained"
                            color={user?.status?.toLowerCase() === "active" ? "secondary" : "primary"}
                            onClick={() => handleStatusToggle(user?.id)}
                          >
                            {user?.status?.toLowerCase() === "active" ? "Deactivate" : "Activate"}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </FormContainer>
      {toastMessage && <IPDCToastMessageResult message={toastMessage.message} type={toastMessage.type} />}
    </Container>
  );
};

export default ActivateOrDeactivateAccount;
