import { useContext, useEffect } from "react";
import MapContext from "../Map/MapContext";
import LayerSwitcher from "ol-layerswitcher";
import { useLegendSync } from "./useLegendSync";

const LegendControl = () => {
  const { map } = useContext(MapContext);

  // Synchronize legend with layer visibility
  useLegendSync(map);

  useEffect(() => {
    if (!map) return;

    const layerSwitcher = new LayerSwitcher({
      activationMode: "click",
      startActive: false,
      label: "",
      collapseLabel: "\u00BB",
      group: false,
      tipLabel: "Legend",
      collapseTipLabel: "Collapse legend",
      reverse: true,
      groupSelectStyle: "children",
      fold: "close",
    });
    // Add LayerSwitcher to the map//console.log(layerSwitcher);
    map.addControl(layerSwitcher);
    // Clean up on component unmount
    return () => {
      map.removeControl(layerSwitcher);
    };
  }, [map]);

  return null;
};

export { LegendControl };
