import React from "react";
import { Grid, Typography, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import { ParkList, InvestorList } from "../../../Shared/PreRenderedComponents";
import { Link } from "react-router-dom";
//prettier-ignore
const Step1 = ({ formData, handleChange, selectedPark, setSelectedPark, parcelInPark, selectedInvestor, setSelectedInvestor, nextStep,}) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="h6">Step 1: Basic Information</Typography>
    </Grid>
    <Grid item xs={12}>
      <ParkList selectedPark={selectedPark} setSelectedPark={setSelectedPark} />
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth={true}>
        <InputLabel id="upin_no-label">Parcel UPIN</InputLabel>
        <Select
          labelId="upin_no-label"
          id="upin"
          name="upin"
          value={formData.upin}
          onChange={handleChange}
          defaultValue={parcelInPark?.length > 0 ? parcelInPark[0]?.upin : ""}
        >
          <MenuItem value="">
            <em>Select Parcel UPIN</em>
          </MenuItem>
          {parcelInPark &&
            parcelInPark?.map((parcel) => (
              <MenuItem key={parcel?.upin} value={parcel?.upin}>
                {parcel?.upin}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <Link to="/registerInvestor">New Investor</Link>
    </Grid>
    <Grid item xs={12}>
      <InvestorList selectedInvestor={selectedInvestor} setSelectedInvestor={setSelectedInvestor} />
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth={true}>
        <InputLabel id="ownership_type-label">Tenure Type</InputLabel>
        <Select
          labelId="ownership_type-label"
          id="ownership_type"
          name="ownership_type"
          value={formData.ownership_type}
          onChange={handleChange}
        >
          <MenuItem value="rent">Shed Rent</MenuItem>
          <MenuItem value="lease">Service Land Lease</MenuItem>
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <Button variant="contained" onClick={nextStep}>
        Next
      </Button>
    </Grid>
  </Grid>
);

export default Step1;
