import React from "react";
import { Grid, Typography, TextField, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const investmentOptions = [
  "Textile & Apparel",
  "ICT",
  "Pharmaceutical",
  "Agro processing",
  "Construction",
  "Food Products and Beverage",
  "Leather & Leather products",
  "Chemicals",
  "Wood Paper & Furniture",
  "Cement",
  "Motor vehicles",
  "Basic Iron & Steel",
  "Machinery",
  "Plastic & Rubber",
  "Other", //  "Foot Wear, Packaging"
];

const Step2 = ({ formData, handleChange, nextStep, prevStep }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="h6">Step 2: Investment & Contract Details</Typography>
    </Grid>
    <Grid item xs={12}>
      <Autocomplete
        freeSolo
        options={investmentOptions}
        value={formData.investment_type}
        onChange={(event, newValue) => {
          handleChange({
            target: {
              name: "investment_type",
              value: newValue,
            },
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth={true}
            label="Investment Type"
            id="investment_type"
            name="investment_type"
            required
          />
        )}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth={true}
        type="date"
        label="Contract Issue Date"
        id="contract_issue"
        name="contract_issue"
        value={formData.contract_issue}
        onChange={handleChange}
        required
        InputLabelProps={{ shrink: true }}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth={true}
        type="date"
        label="Contract Renewal Date"
        id="contract_renewal"
        name="contract_renewal"
        value={formData.contract_renewal}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
      />
    </Grid>
    <Grid item xs={12}>
      <Button variant="contained" onClick={prevStep}>
        Previous
      </Button>
      <Button variant="contained" onClick={nextStep} style={{ marginLeft: "8px" }}>
        Next
      </Button>
    </Grid>
  </Grid>
);

export default Step2;
