import React, { useState, useEffect } from "react";
import { CircularProgress, Box } from "@mui/material";
import { IPDCReportTemplate } from "../../../../components";
import { GetDepartments } from "../../Shared/CommonData/CommonData";
export default function DepartmentList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      const newData = await GetDepartments();
      if (newData) {
        setData(newData);
        console.log(newData);
      }
      setLoading(false);
      return true;
    };
    fetchUsers();
  }, []);
  useEffect(() => {}, [data]);
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Box>
      {data && data.length > 0 ? (
        <IPDCReportTemplate
          title={"System Users"}
          data={data}
          setData={setData}
          isPrintable={false}
          isEditable={false}
          showHeader={false}
          isDeletable={false}
        />
      ) : (
        <>{"No Data"}</>
      )}
    </Box>
  );
}
