import { useEffect, useState } from "react";
import { GetAgreements } from "../CommonData/CommonData";
import { Autocomplete, TextField } from "@mui/material";

export default function AgreementList({ selectedAgreement, setSelectedAgreement, isRequired = true }) {
  const [agreementsData, setAgreementsData] = useState([]);

  useEffect(() => {
    const fetchAgreements = async () => {
      const agreData = await GetAgreements();
      const filteredAgreementsData = agreData.filter(agreement => agreement.id !== "");
      setAgreementsData(filteredAgreementsData);
      //console.log("agreements");
      //console.log(filteredAgreementsData);
    };
    fetchAgreements();
  }, []);

  return (
    <>
      <Autocomplete
        fullWidth={true}
        options={agreementsData}
        getOptionLabel={(option, index) => (option ? option.id : "")}
        value={selectedAgreement}
        onChange={(event, newValue) => {
          setSelectedAgreement(newValue);
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Select an agreement"
            placeholder="Select an agreement"
            variant="outlined"
            required={isRequired}
          />
        )}
      />
    </>
  );
}
export function AgreementListInPark({ parkId, selectedAgreement, setSelectedAgreement, isRequired = true }) {
  const [agreementsData, setAgreementsData] = useState([]);

  useEffect(() => {
    const fetchAgreements = async () => {
      const agreData = await GetAgreements(parkId);
      const uniqueIds = new Set();

      const filteredAgreementsData = agreData.filter(agreement => {
        // Check for non-empty `id` and ensure it hasn't been encountered before
        if (agreement.id && !uniqueIds.has(agreement.id)) {
          uniqueIds.add(agreement.id);
          return true;
        }
        return false;
      });
      setAgreementsData(filteredAgreementsData);
      //console.log("agreements");
      //console.log(filteredAgreementsData);
    };
    fetchAgreements();
  }, []);

  return (
    <>
      <Autocomplete
        fullWidth={true}
        options={agreementsData}
        getOptionLabel={(option, index) => (option ? option.id : "")}
        value={selectedAgreement}
        onChange={(event, newValue) => {
          setSelectedAgreement(newValue);
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Select an agreement"
            placeholder="Select an agreement"
            variant="outlined"
            required={isRequired}
          />
        )}
      />
    </>
  );
}
