import React, { useState } from "react";
import {
  TextField,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Grid,
  Alert,
  Button,
  Paper,
  //Typography,
  Container,
} from "@mui/material";
import IPDCFormHeader from "../../../../../components/Forms/IPDCFormHeader";
import { Search } from "@mui/icons-material";
import FormContainer from "../../../../../components/Forms/FormContainer";

import { ExecuteApiRequest, ExecuteApiToPost, GetDataFromApiWithParams } from "../../../../../services/api/ExecuteApiRequests";

const initialData = {
  agreementId: "",
  paymentId: "",
  payerName: "",
  paymentAmount: "",
  paymentDate: "",
  paymentType: "Rental",
  leaseDuration: "",
  rentalPeriod: "",
};
/*invoice_no,
agreement_id,
amount,
collection_date,
collection_type,
bank_name,
reference_no,
description,
penality,
recieved_by*/

const CollectPayment = () => {
  const [paymentType, setPaymentType] = useState("Rental");
  const [formData, setFormData] = useState(initialData);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [agreementDetail, setAgreementDetail] = useState(null);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.target.value);
    setFormData({ ...formData, paymentType: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await ExecuteApiToPost("finances/collections", formData);
      if (result) {
        setSuccessMessage("Payment collection information successfully saved!");
        setFormData(initialData);
      }
    } catch (err) {
      console.log(err);
      setErrorMessage("Saving paymet was unsuccessful, Please try again!");
    }
  };
  const fetchAgreementDetails = async () => {
    try {
      const resultData = await GetDataFromApiWithParams(`agreements/${formData.agreementId}`);
      setAgreementDetail(resultData);
      if (resultData[0].ownership_type === "Shed Rental") {
        setPaymentType("Rental");
      } else {
        setPaymentType("Lease");
      }
      //console.log(resultData);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <FormContainer title="Collect Payment" onSubmit={handleSubmit}>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField
              fullWidth={true}
              label="Agreement Id"
              name="agreementId"
              value={formData.agreementId}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={2}>
            <Button startIcon={<Search />} onClick={fetchAgreementDetails}>
              Search Detail
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth={true}
              label="Payment ID"
              name="paymentId"
              value={formData.paymentId}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth={true}
              label="Payer Name"
              name="payerName"
              value={formData.payerName}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth={true}
              label="Payment Amount"
              name="paymentAmount"
              type="number"
              value={formData.paymentAmount}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth={true}
              label="Payment Date"
              name="paymentDate"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formData.paymentDate}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Payment Type</FormLabel>
              <RadioGroup
                aria-label="paymentType"
                name="paymentType"
                value={paymentType}
                onChange={handlePaymentTypeChange}
                row
              >
                <FormControlLabel value="Rental" control={<Radio />} label="Rental" />
                <FormControlLabel value="Lease" control={<Radio />} label="Lease" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {paymentType === "Rental" && (
            <Grid item xs={12}>
              <TextField
                fullWidth={true}
                label="Rental Period"
                name="rentalPeriod"
                value={formData.rentalPeriod}
                onChange={handleInputChange}
              />
            </Grid>
          )}
          {paymentType === "Lease" && (
            <Grid item xs={12}>
              <TextField
                fullWidth={true}
                label="Lease Duration"
                name="leaseDuration"
                value={formData.leaseDuration}
                onChange={handleInputChange}
              />
            </Grid>
          )}
          {(errorMessage || successMessage) && (
            <Grid item xs={12}>
              <Alert variant="filled" severity={errorMessage ? "error" : "success"}>
                {errorMessage || successMessage}
              </Alert>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container md={6} p={1}>
        <FormControl fullWidth={true}>
          <Paper elevation={1} fullWidth={true} p={2} sx={{ height: "100%" }}>
            <Box alignContent={"right"}>
              <IPDCFormHeader title={"Agreement Detail"} />
              {agreementDetail &&
                agreementDetail.length > 0 &&
                agreementDetail.map((item, index) => (
                  <>
                    <Container gap={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          upin:
                        </Grid>
                        <Grid item xs={6}>
                          {item.upin}
                        </Grid>

                        <Grid item xs={6}>
                          Company Name
                        </Grid>
                        <Grid item xs={6}>
                          {item.company_name}
                        </Grid>

                        <Grid item xs={6}>
                          Company Name
                        </Grid>
                        <Grid item xs={6}>
                          {item.company_name}
                        </Grid>

                        <Grid item xs={6}>
                          Service Fee
                        </Grid>
                        <Grid item xs={6}>
                          {"1.5 usd/m2"}
                        </Grid>

                        <Grid item xs={6}>
                          contract_type
                        </Grid>
                        <Grid item xs={6}>
                          {item.ownership_type}
                        </Grid>
                        <Grid item xs={6}>
                          contract_status
                        </Grid>
                        <Grid item xs={6}>
                          {"Operational"}
                        </Grid>
                        <Grid item xs={12}>
                          {""}
                        </Grid>
                      </Grid>
                    </Container>
                  </>
                ))}
            </Box>
          </Paper>
        </FormControl>
      </Grid>
    </FormContainer>
  );
};

export default CollectPayment;
