import React from "react";
import { Grid, Typography, TextField, Button } from "@mui/material";

const Step3 = ({ formData, handleChange, nextStep, prevStep }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="h6">Step 3: Production, Conditions and Capacity</Typography>
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth={true}
        type="number"
        label="Production Capacity"
        id="production_capacity"
        name="production_capacity"
        value={formData.production_capacity}
        onChange={handleChange}
        required
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth={true}
        type="date"
        label="Handover Date"
        id="handover_date"
        name="handover_date"
        value={formData.handover_date}
        onChange={handleChange}
        required
        InputLabelProps={{ shrink: true }}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth={true}
        type="date"
        label="Production Commencement Date"
        id="production_commencement_date"
        name="production_commencement_date"
        value={formData.production_commencement_date}
        onChange={handleChange}
        required
        InputLabelProps={{ shrink: true }}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth={true}
        type="number"
        label="Export Capacity"
        id="export_capacity"
        name="export_capacity"
        value={formData.export_capacity}
        onChange={handleChange}
        required
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth={true}
        type="number"
        label="Employment Capacity"
        id="employee_capacity"
        name="employee_capacity"
        value={formData.employee_capacity}
        onChange={handleChange}
        required
      />
    </Grid>
    <Grid item xs={12}>
      <Button variant="contained" onClick={prevStep}>
        Previous
      </Button>
      <Button variant="contained" onClick={nextStep} style={{ marginLeft: "8px" }}>
        Next
      </Button>
    </Grid>
  </Grid>
);

export default Step3;
