import { Route } from "react-router-dom";
import AssignInfoToMap from "../../../pages/Views/LandInfraDevelopment/GISView/Forms/AssignInfoToMap/AssignInfoToMap";
import ExportDataPage from "../../../pages/Views/LandInfraDevelopment/GISView/MapRelated/ExportDataPage";
import PrintMapForm from "../../../pages/Views/LandInfraDevelopment/GISView/Forms/PrintMapForm";
import {
  ViewMap,
  ViewInfrastructureMap,
  EditMap,
  ViewMapByCategoryPage,
  ViewPlanningMap,
  ViewCadastreMap,
} from "../../../pages/Views/LandInfraDevelopment/GISView/MapRelated/Views";
import GISReports from "../../../pages/Views/LandInfraDevelopment/GISView/Reports/GISReports";
import GISDashboard from "../../../pages/Views/LandInfraDevelopment/GISView/GISDashboard";
import ImportExistingParkData from "../../../pages/Views/LandInfraDevelopment/GISView/Forms/ImportSpatialData/ImportExistingParkData";
import MapDataProvider from "../../../pages/Views/LandInfraDevelopment/GISView/MapRelated/MapData/MapDataProvider";
import EditImportedData from "../../../pages/Views/LandInfraDevelopment/GISView/Forms/ImportSpatialData/EditImportedData";
export default function GISRoutes() {
  return (
    <>
      <Route path="/" element={<GISDashboard />} />
      <Route path="/viewMap" element={<ViewMap />} />
      <Route path="/viewMapByCategory" element={<ViewMapByCategoryPage />} />
      
      <Route path="/importSpatial" element={<ImportExistingParkData />} />
      <Route path="/assignInfo" element={<AssignInfoToMap />} />
      <Route path="/editMap" element={<EditMap />} />
      <Route path="/printMap" element={<PrintMapForm />} />
      <Route path="/exportMap" element={    <MapDataProvider><ExportDataPage /></MapDataProvider>} />
      <Route path="/infrastructureMap" element={<ViewInfrastructureMap />} />
      <Route path="/viewPlanningMap" element={<ViewPlanningMap />} />
      <Route path="/viewCadastreMap" element={<ViewCadastreMap />} />
      <Route path="/editImportedData" element={<EditImportedData />} />
      <Route path="/gisReports" element={<GISReports />} />
    </>
  );
}
