import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Snackbar,
  Grid,
} from "@mui/material";
import { UpdateAndGetResponse } from "../../../../../../../services/api/ExecuteApiRequests";

const AssignParkForm = ({ featureInfo }) => {
  const [formData, setFormData] = useState({
    id: "",
    region: "",
    name: "",
    description: "",
  });

  const [snackbar, setSnackbar] = useState({ open: false, message: "" });

  useEffect(() => {
    console.log("featureInfo received:", featureInfo);
    if (featureInfo) {
      const newFormData = {
        id: featureInfo.id || "",
        region: featureInfo.region || "",
        name: featureInfo.name || "",
        description: featureInfo.description || "",
      };
      //  console.log("Updating formData to:", newFormData);
      setFormData(newFormData);
    }
  }, [featureInfo]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await UpdateAndGetResponse(
        `parks/${formData.id}`,
        formData
      );
      console.log(response);
      if (response.message) {
        setSnackbar({ open: true, message: "Updating park info successful!" });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Updating park info failed: ${error.message}`,
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={12} align={"center"}>
          <Typography variant="h5" gutterBottom>
            Park Info
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth={true}
            margin="normal"
            id="region"
            label="Region"
            value={formData.region}
            onChange={(e) =>
              setFormData({ ...formData, region: e.target.value })
            }
            disabled
            InputProps={{ sx: { height: "30px" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth={true}
            margin="normal"
            id="id"
            label="ID"
            value={formData.id}
            onChange={handleChange}
            disabled
            InputProps={{ sx: { height: "30px" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth={true}
            margin="normal"
            id="name"
            label="Name"
            value={formData.name}
            onChange={handleChange}
            required
            InputProps={{ sx: { height: "30px" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            labelId="investor-label"
            fullWidth={true}
            margin="normal"
            id="description"
            label="Description"
            value={formData.description}
            onChange={handleChange}
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth={true}
            sx={{ mt: 2 }}
          >
            Assign Info
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default AssignParkForm;
