import { createContext } from "react";
export const MapDataContext = createContext({
  park: null,
  setPark: () => {},
  blocks: null,
  setBlocks: () => {},
  parcels: null,
  setParcels: () => {},
  buildings: null,
  setBuildings: () => {},
  infras: null,
  setInfras: () => {},
  greenAreas: null,
  setGreenAreas: () => {},
  importeds: null,
  setImporteds: () => {},
  roads: null,
  setRoads: () => {},
  gcps: null,
  setGCPs: () => {},
  wasteDisposals: null,
  setWasteDisposals: () => {},
  waters: null,
  setWaters: () => {},
  powers: null,
  setPowers: () => {},
  communications: null,
  setCommunications: () => {},
  storages: null,
  setStorages: () => {},
  surveyDatas: null,
  setSurveyDatas: () => {},
  parcelGrouping: null,
  setParcelGrouping: () => {},
  plans: null,
  setPlans: () => {},
  sheds: null,
  setSheds: () => {},
  zones: null,
  setZones: () => {},
  unapprovedImports: null,
  setUnapprovedImports: () => {},
});
